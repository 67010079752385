import useSWR from 'swr'

import { PaginatedResponse, Pagination } from '~types/ui'
import { Owner, OwnerFilters } from '~services/owner'
import { fetcher, tokenMiddleware } from '~services/utils'

export const useOwners = (
  ownerFilters: OwnerFilters = {},
  { limit, offset, previous }: Pagination = { limit: 6, offset: 0, previous: false }
) => {
  // eslint-disable-next-line
  const { data, isLoading, error, mutate } = useSWR<PaginatedResponse<Owner[]>>(
    [
      `owners/?limit=${limit}&offset=${offset}&previous=${previous}${
        ownerFilters.role ? `&role=${ownerFilters.role}` : ''
      }${ownerFilters.approval_status ? `&approval_status=${ownerFilters.approval_status}` : ''}`
    ],
    // eslint-disable-next-line
    ([url, method, headers, body]: [string, string, any, any]) =>
      fetcher(url, 'GET', headers, body),
    { use: [tokenMiddleware] }
  )

  return {
    owners: data,
    error,
    mutate
  }
}
