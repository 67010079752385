import { fetcher } from '~services/utils'

const deleteListing = async (url: string, { authToken }: { authToken: string | undefined }) => {
  const headers = {
    Authorization: `Bearer ${authToken}`
  }

  return await fetcher(url, 'DELETE', headers)
}

export const deleteListingById = (listing_id: number, jwtToken: string) => {
  deleteListing(`listings/${listing_id}`, { authToken: jwtToken })
}
