import useSWRMutation from 'swr/mutation'
import useSWRImmutable from 'swr/immutable'
import { useAuthenticator } from '@aws-amplify/ui-react'

import { fetcher, fileFetcher } from '~services/utils'
import { SubmitListingType } from '~components/listingForm'
import {
  ListingDetails,
  ListingsApprovalStatusEnum,
  useUpdateListingStatus
} from '~services/listings'
import { useUser } from '~services/owner'

export const createListing = async (
  url: string,
  { arg }: { arg: { submitListing: SubmitListingType; authToken: string | undefined } }
) => {
  const headers = {
    Authorization: `Bearer ${arg.authToken}`
  }

  return (await fetcher(url, 'POST', headers, arg.submitListing)) as ListingDetails
}

export const useListingCreation = (images: File[] | null) => {
  //Creates listing on trigger and returns an object containing the listing ID
  const { data: listing, trigger } = useSWRMutation(`listings/`, createListing)
  const { trigger: updateStatus } = useUpdateListingStatus(
    listing ? listing?.listing_id : undefined
  )
  const { jwtToken } = useUser()

  //Attaches the images to the listing based on the listing ID and the user email from the logged-in user.
  const { user } = useAuthenticator(context => [context.user])
  const { isLoading, error } = useSWRImmutable(
    listing && listing.listing_id && user && user.attributes
      ? [`listings/${listing.listing_id}/attachments/`, images, user.attributes.email]
      : null,
    ([url, images, email]: [string, File[], string]) => {
      const headers = {
        Authorization: `Bearer ${jwtToken}`
      }
      images.map(async image => {
        const data = new FormData()
        data.append('file', image)
        await fileFetcher(
          url + `?name=${image.name}&description=${image.name}&created_by=${email}`,
          'POST',
          data,
          headers
        )
      })
    }
  )

  updateStatus({ newStatus: ListingsApprovalStatusEnum.submitted, authToken: jwtToken })

  return {
    trigger,
    listing,
    isLoading,
    error
  }
}
