import useSWR from 'swr'
import { FilterPanelInputValues } from '~components/filterPanel'
import { CrudTypes } from '~types/api'
import { VoucherLocation } from './types'

export const fetcher = <T>(
  url: string | URL,
  method: CrudTypes,
  headers?: Record<string, string>,
  body?: T
) =>
  fetch(`${url}`, {
    method,
    headers: { 'Content-Type': 'application/json', ...headers },
    body: body ? JSON.stringify(body) : undefined
  })
    .then(r => r.json())
    .catch(error => {
      throw error
    })

export const useStadtguthabenAPI = (locationFilter: FilterPanelInputValues) => {
  const { data, error, isLoading } = useSWR<VoucherLocation[]>(
    [
      'https://api.stadtguthaben.de/vendor/arup/v1/stores',
      'GET',
      {
        Authorization:
          'Bearer BKOy49yUfrf3Ft32ngcdoWAhPWQpyqCozGLZk05kXypnmgEZuToorRDPE2ZrluIlb-hGByYaYkBiUhdL5oFuDzVrtLnT8QKLDVPjFLHbyk0PdWFiL0mXEzq7hg7EzbTD'
      }
    ],
    ([url, method, headers]: [string, 'GET', Record<string, string>]) =>
      fetcher(url, method, headers)
  )

  const filteredData = data?.filter(location => {
    const { gutscheine } = locationFilter
    const hasAcceptor = location.filter.acceptor
    const hasSeller = location.filter.seller

    switch (gutscheine) {
      case 'both':
        return hasAcceptor && hasSeller
      case 'accepts':
        return hasAcceptor
      case 'sells':
        return hasSeller
      default:
        // Include all data when gutscheine is not specified or 'either'
        return true
    }
  })

  return {
    filteredData,
    error,
    isLoading
  }
}
