import { ChangeEvent } from 'react'
import {
  Autocomplete,
  Box,
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  RadioGroup,
  Tooltip,
  Typography
} from '@mui/material'
import { Info as InfoIcon } from '@mui/icons-material'
import { useTheme } from '@mui/material/styles'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import { StyledRadio, StyledTextField } from '~components/input'
import { Address, useAddressSearch } from '~services/address'
import {
  GeneralListingUseEnum,
  ListingTypesEnum,
  ListingUsesEnum,
  OwnerRelationshipsEnum
} from '~services/listings'

import { LeaseAgreementEnum, VacancyDetailsOneFormProps, stepOneSchema, StepOneSchemaType } from '.'

export const FormStepOne = ({
  onSubmit,
  activeStep,
  handleBack,
  prefilledData,
  isEditMode
}: VacancyDetailsOneFormProps) => {
  const theme = useTheme()
  const { trigger, addresses } = useAddressSearch()

  const { control, handleSubmit, watch, setValue, clearErrors } = useForm<StepOneSchemaType>({
    resolver: zodResolver(stepOneSchema),
    mode: 'all'
  })

  const handleAddressSearch = async (address: string) => {
    await trigger(address)
  }

  const handleAddressSelect = async (address: Address | null) => {
    clearErrors('address')
    if (address) {
      setValue('address', {
        streetname_number: address.streetname_number,
        address_id: address.address_id
      })
    } else {
      setValue('address', { streetname_number: '', address_id: 0 })
    }
  }

  const handleRadioGroupChange =
    <T,>(onChange: (value: T) => void) =>
    (event: ChangeEvent<HTMLInputElement>, newValue: string) => {
      clearErrors('leaseEnd')
      onChange(newValue as T)
    }

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Grid container width="50%" spacing={2}>
        <Grid item xs={12}>
          <Controller
            name="title"
            control={control}
            defaultValue={prefilledData.title}
            render={({ field, fieldState: { error } }) => (
              <StyledTextField
                {...field}
                name={field.name}
                label="Objekt Titel/Name*"
                variant="outlined"
                error={Boolean(error)}
                helperText={error?.message}
                inputRef={field.ref}
                size="small"
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="address"
            defaultValue={prefilledData.address}
            control={control}
            render={({ field: { value, ref }, fieldState: { error } }) => (
              <Autocomplete
                options={addresses ? addresses.items : []}
                sx={{ width: 300 }}
                getOptionLabel={option => option.streetname_number}
                onInputChange={(e, value) => handleAddressSearch(value)}
                onChange={(e, value) => handleAddressSelect(value)}
                defaultValue={value}
                noOptionsText="Keine Optionen"
                disabled={isEditMode}
                renderInput={params => (
                  <StyledTextField
                    {...params}
                    label="Adresse*"
                    error={Boolean(error)}
                    helperText={error?.message}
                    inputRef={ref}
                    variant="outlined"
                    size="small"
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography color="primary.dark" variant="body1">
            Um welche Art von Immobilie handelt es sich?
          </Typography>

          <Controller
            control={control}
            name="transactionType"
            defaultValue={prefilledData.transactionType}
            render={({ field }) => (
              <RadioGroup
                {...field}
                row
                onChange={handleRadioGroupChange<ListingTypesEnum>(field.onChange)}
              >
                <Grid item xs={4}>
                  <FormControlLabel
                    value={ListingTypesEnum.rent}
                    control={
                      <StyledRadio
                        sx={{
                          color: 'primary.main',
                          '&.Mui-checked': {
                            color: 'primary.dark'
                          }
                        }}
                      />
                    }
                    label={
                      <Typography
                        color={
                          field.value === ListingTypesEnum.rent
                            ? 'primary.dark'
                            : 'secondary.contrastText'
                        }
                      >
                        Mietimmobilien
                      </Typography>
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControlLabel
                    value={ListingTypesEnum.buy}
                    control={
                      <StyledRadio
                        sx={{
                          color: 'primary.main',
                          '&.Mui-checked': {
                            color: 'primary.dark'
                          }
                        }}
                      />
                    }
                    label={
                      <Typography
                        color={
                          field.value === ListingTypesEnum.buy
                            ? 'primary.dark'
                            : 'secondary.contrastText'
                        }
                      >
                        Kaufimmobilien
                      </Typography>
                    }
                  />
                </Grid>
              </RadioGroup>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography color="primary.dark" variant="body1">
            Um welche Art von Vertrag handelt es sich?
          </Typography>

          <Controller
            control={control}
            name="leaseAgreement"
            defaultValue={prefilledData.leaseAgreement}
            render={({ field }) => (
              <RadioGroup
                {...field}
                row
                onChange={handleRadioGroupChange<LeaseAgreementEnum>(field.onChange)}
              >
                <Grid item xs={4}>
                  <FormControlLabel
                    value={LeaseAgreementEnum.limited}
                    control={
                      <StyledRadio
                        disabled={watch('transactionType') === ListingTypesEnum.buy}
                        sx={{
                          color: 'primary.main',
                          '&.Mui-checked': {
                            color: 'primary.dark'
                          }
                        }}
                      />
                    }
                    label={
                      <Typography
                        color={
                          watch('transactionType') !== ListingTypesEnum.buy &&
                          field.value === LeaseAgreementEnum.limited
                            ? 'primary.dark'
                            : 'secondary.contrastText'
                        }
                      >
                        Befristet
                      </Typography>
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControlLabel
                    value={LeaseAgreementEnum.unlimited}
                    control={
                      <StyledRadio
                        disabled={watch('transactionType') === ListingTypesEnum.buy}
                        sx={{
                          color: 'primary.main',
                          '&.Mui-checked': {
                            color: 'primary.dark'
                          }
                        }}
                      />
                    }
                    label={
                      <Typography
                        color={
                          watch('transactionType') !== ListingTypesEnum.buy &&
                          field.value === LeaseAgreementEnum.unlimited
                            ? 'primary.dark'
                            : 'secondary.contrastText'
                        }
                      >
                        Unbefristet
                      </Typography>
                    }
                  />
                </Grid>
              </RadioGroup>
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Controller
              control={control}
              name="leaseStart"
              defaultValue={prefilledData.leaseStart}
              render={({ field, fieldState: { error } }) => (
                <>
                  <DatePicker
                    disabled={watch('transactionType') === ListingTypesEnum.buy}
                    {...field}
                    onChange={value => {
                      return field.onChange(value ? dayjs(value) : dayjs(new Date()))
                    }}
                    label="Mietbeginn*"
                    sx={{
                      width: '100%',
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          border: `1px solid ${
                            error ? theme.palette.error.main : theme.palette.primary.main
                          }`,
                          borderRadius: '6px'
                        },
                        '&:hover fieldset': {
                          borderColor: error ? theme.palette.error.main : theme.palette.primary.dark
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: error ? theme.palette.error.main : theme.palette.primary.dark
                        },
                        '& input::placeholder': {
                          color: 'blue',
                          opacity: 1
                        }
                      }
                    }}
                  />
                  <Typography color="error" mt="0.5rem" ml="0.9rem">
                    {error?.message}
                  </Typography>
                </>
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} md={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Controller
              control={control}
              name="leaseEnd"
              defaultValue={prefilledData.leaseEnd}
              render={({ field, fieldState: { error } }) => (
                <>
                  <DatePicker
                    {...field}
                    disabled={
                      watch('transactionType') === ListingTypesEnum.buy ||
                      watch('leaseAgreement') === LeaseAgreementEnum.unlimited
                    }
                    onChange={value => field.onChange(value ? dayjs(value) : dayjs(new Date()))}
                    label={
                      watch('leaseAgreement') === LeaseAgreementEnum.unlimited
                        ? 'Mietende'
                        : 'Mietende*'
                    }
                    sx={{
                      color: 'blue',
                      width: '100%',
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          border: `1px solid ${
                            error ? theme.palette.error.main : theme.palette.primary.main
                          }`,
                          borderRadius: '6px'
                        },
                        '&:hover fieldset': {
                          borderColor: error ? theme.palette.error.main : theme.palette.primary.dark
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: error ? theme.palette.error.main : theme.palette.primary.dark
                        }
                      }
                    }}
                  />
                  <Typography color="error" mt="0.5rem" ml="0.9rem">
                    {error?.message}
                  </Typography>
                </>
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="price"
            defaultValue={prefilledData.price}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <StyledTextField
                {...field}
                id="price"
                label={
                  watch('transactionType') === ListingTypesEnum.rent
                    ? 'Kaltmiete pro Monat* (€)'
                    : 'Kaufpreis* (€)'
                }
                variant="outlined"
                error={Boolean(error)}
                helperText={error?.message}
                size="small"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="serviceCosts"
            defaultValue={prefilledData.serviceCosts}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <StyledTextField
                {...field}
                id="serviceCosts"
                label="Nebenkosten (€)"
                variant="outlined"
                error={Boolean(error)}
                helperText={error?.message}
                size="small"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6} display="inline-flex" sx={{ alignItems: 'baseline' }}>
          <Controller
            name="retailSpaceSize"
            defaultValue={prefilledData.retailSpaceSize}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <StyledTextField
                {...field}
                id="retailSpaceSize"
                label="Nutzbare Fläche* (m²)"
                variant="outlined"
                error={Boolean(error)}
                helperText={error?.message}
                size="small"
              />
            )}
          />
          <Tooltip
            title={
              <Box
                sx={{
                  backgroundColor: 'white',
                  color: 'primary.main',
                  border: '.05rem solid',
                  borderColor: 'neutral.light',
                  borderRadius: '0.5rem',
                  padding: '.5rem'
                }}
              >
                <Box sx={{ display: 'flex', p: '.25rem' }}>
                  <Typography>
                    Summe der Grundfläche mit Nutzungen derjenige Teil der Netto-Raumfläche, der der
                    Nutzung des Bauwerks aufgrund seiner Zweckbestimmung dient. (DIN 277)
                  </Typography>
                </Box>
              </Box>
            }
          >
            <IconButton sx={{ py: 0 }}>
              <InfoIcon style={theme.typography.h5} sx={{ color: 'primary.main' }} />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={12} md={6} display="inline-flex" sx={{ alignItems: 'baseline' }}>
          <Controller
            name="propertySize"
            defaultValue={prefilledData.propertySize}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <StyledTextField
                {...field}
                id="propertySize"
                label="Grundstücksfläche (m²)"
                variant="outlined"
                error={Boolean(error)}
                helperText={error?.message}
                size="small"
              />
            )}
          />
          <Tooltip
            title={
              <Box
                sx={{
                  backgroundColor: 'white',
                  color: 'primary.main',
                  border: '.05rem solid',
                  borderColor: 'neutral.light',
                  borderRadius: '0.5rem',
                  padding: '.5rem'
                }}
              >
                <Box sx={{ display: 'flex', p: '.25rem' }}>
                  <Typography>
                    Fläche, die durch die Grundstücksgrenzen gebildet wird und die im
                    Liegenschaftskataster sowie im Grundbuch ausgewiesen ist. (DIN 277)
                  </Typography>
                </Box>
              </Box>
            }
          >
            <IconButton sx={{ py: 0 }}>
              <InfoIcon style={theme.typography.h5} sx={{ color: 'primary.main' }} />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="propertyType"
            defaultValue={prefilledData.propertyType}
            control={control}
            render={({ field }) => (
              <StyledTextField {...field} select size="small" variant="outlined">
                <MenuItem value={GeneralListingUseEnum.commercial}>Gewerblich</MenuItem>
                <MenuItem value={GeneralListingUseEnum.residential}>Wohnen</MenuItem>
                <MenuItem value={GeneralListingUseEnum.other}>Andere</MenuItem>
              </StyledTextField>
            )}
          />
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="propertyStatus"
            defaultValue={prefilledData.propertyStatus}
            control={control}
            render={({ field }) => (
              <StyledTextField {...field} select size="small" variant="outlined">
                <MenuItem value={OwnerRelationshipsEnum.rented_out}>Vermietet</MenuItem>
                <MenuItem value={OwnerRelationshipsEnum.vacant}>Leer</MenuItem>
                <MenuItem value={OwnerRelationshipsEnum.own_use}>Eigennutzung</MenuItem>
              </StyledTextField>
            )}
          />
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="propertyUsage"
            defaultValue={prefilledData.propertyUsage}
            control={control}
            render={({ field }) => (
              <StyledTextField {...field} select size="small" variant="outlined">
                <MenuItem value={ListingUsesEnum.house}>Haus</MenuItem>
                <MenuItem value={ListingUsesEnum.apartment}>Wohnung</MenuItem>
                <MenuItem value={ListingUsesEnum.senior_apartment}>Seniorenwohnung</MenuItem>
                <MenuItem value={ListingUsesEnum.office}>Büro</MenuItem>
                <MenuItem value={ListingUsesEnum.shop}>Geschäft</MenuItem>
                <MenuItem value={ListingUsesEnum.storage_room}>Lagerraum</MenuItem>
                <MenuItem value={ListingUsesEnum.production}>Produktionsstätte</MenuItem>
                <MenuItem value={ListingUsesEnum.trade}>Handel</MenuItem>
                <MenuItem value={ListingUsesEnum.garage}>Garage</MenuItem>
                <MenuItem value={ListingUsesEnum.parking_space}>Parkplatz</MenuItem>
                <MenuItem value={ListingUsesEnum.gastronomy}>Gastronomie</MenuItem>
                <MenuItem value={ListingUsesEnum.vacant_plot}>Bauland</MenuItem>
                <MenuItem value={ListingUsesEnum.other}>Sonstiges</MenuItem>
              </StyledTextField>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Box mt="1.5rem" sx={{ display: 'flex', gap: 8 }}>
            <Button
              variant="contained"
              onClick={handleBack}
              sx={{ visibility: activeStep > 0 ? 'visible' : 'hidden', width: '13rem' }}
            >
              Zurück
            </Button>
            <Button variant="contained" type="submit" sx={{ width: '13rem' }}>
              {activeStep === 2 - 1 ? 'Absenden' : 'Nächster Schritt'}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
