import { fetcher } from '~services/utils'

import { CreateOwnerBody } from '.'

export const updateOwner = async (
  url: string,
  { arg }: { arg: { ownerData: CreateOwnerBody; authToken: string | undefined } }
) => {
  const headers = {
    Authorization: `Bearer ${arg.authToken}`
  }

  return (await fetcher(
    `owners/${arg.ownerData.username}`,
    'PATCH',
    headers,
    arg.ownerData
  )) as CreateOwnerBody
}
