// FilterContext.tsx
import React, { createContext, useContext, useState, ReactNode } from 'react'

// Define the types
type FilterContextProps = {
  filters: Record<string, string | number | null>
  updateFilters: (newFilters: Record<string, string | number | null>) => void
}

const FilterContext = createContext<FilterContextProps | undefined>(undefined)

type FilterProviderProps = {
  children: ReactNode
}

// Create the context provider
export const FilterProvider: React.FC<FilterProviderProps> = ({ children }) => {
  const [filters, setFilters] = useState<Record<string, string | number | null>>({})

  const updateFilters = (newFilters: Record<string, string | number | null>) => {
    setFilters(newFilters)
  }

  return (
    <FilterContext.Provider value={{ filters, updateFilters }}>{children}</FilterContext.Provider>
  )
}

// Create a custom hook for using the FilterContext
export const useFilterContext = (): FilterContextProps => {
  const context = useContext(FilterContext)
  if (!context) {
    throw new Error('useFilterContext must be used within a FilterProvider')
  }
  return context
}
