import { SubmitHandler } from 'react-hook-form'
import { type Dayjs } from 'dayjs'

import {
  GeneralListingUseEnum,
  ListingTypesEnum,
  ListingUsesEnum,
  MarketingMethodsEnum,
  OwnerRelationshipsEnum,
  UtilitiesEnum,
  EnergyClassEnum,
  ListingDetails,
  Ratings
} from '~services/listings'
import { Address } from '~services/address'

export type FormProps = {
  onSubmit: SubmitHandler<
    VacancyDetailsOneFormType | VacancyDetailsTwoFormType | VacancyDetailsThreeFormType
  >
  activeStep: number
  handleBack: () => void
}

export type FormDataType = {
  step1: VacancyDetailsOneFormType
  step2: VacancyDetailsTwoFormType
  step3: VacancyDetailsThreeFormType
}

export type VacancyDetailsOneFormProps = FormProps & {
  prefilledData: VacancyDetailsOneFormType
  isEditMode: boolean
}

export type VacancyDetailsTwoFormProps = FormProps & {
  prefilledData: VacancyDetailsTwoFormType
}

type Attachment = {
  name: string
  description: string
  attachment_id: number
  s3_presigned_url: string
}

type AttachmentList = Attachment[] | undefined

export type VacancyDetailsThreeFormProps = FormProps & {
  prefilledData: VacancyDetailsThreeFormType
  attachments: AttachmentList
}

export type VacancyDetailsOneFormType = {
  title: string
  address: Address
  transactionType: ListingTypesEnum
  leaseAgreement?: LeaseAgreementEnum
  leaseStart: Dayjs
  leaseEnd?: Dayjs
  price: string
  serviceCosts?: string
  retailSpaceSize: string
  propertySize: string
  propertyType: GeneralListingUseEnum
  propertyStatus: OwnerRelationshipsEnum
  propertyUsage: ListingUsesEnum
}

export enum LeaseAgreementEnum {
  limited = 'limited',
  unlimited = 'unlimited'
}

export type VacancyDetailsTwoFormType = {
  description: string
  numRooms?: string
  floor?: string
  numFloors?: string
  utilities?: UtilitiesEnum[]
  constructionYear?: string
  renovationYear?: string
  monumentStatus?: string | undefined
  hasBasement?: string | undefined
  hasAttic?: string | undefined
  furnished?: string | undefined
  vacantSince?: Dayjs
  prevMarketingMethods?: MarketingMethodsEnum
  mainRemarketingProblems?: string
  investmentDetails?: string
  reqImprovementsCommercialUse?: string
  neighbourCooperationDetails?: string
}

export type VacancyDetailsThreeFormType = {
  energyConsumption?: string
  energyClass?: EnergyClassEnum
  heatingType?: string
  heatingIssueYear?: string
  innerAppearanceRating?: Ratings
  outerAppearanceRating?: Ratings
  supplyTechnologyRating?: Ratings
  internetMbits?: string
  appearanceAdditionalInformation?: string
  accessibilityRating: Ratings
  accessibilityExplanation?: string
  other?: string
  wishesConsultation: string
  images?: FileType[]
}

export type FileType = {
  id: string
  file: File
}

export type SubmitListingType = Omit<
  ListingDetails,
  'address' | 'owner' | 'listing_id' | 'approval_status' | 'created_at' | 'attachments'
>

type OnUploadFunction = (files: File[]) => void

export type CropperProps = {
  onUpload: OnUploadFunction
  setImageComplete: React.Dispatch<React.SetStateAction<boolean>>
  existingImages: FileType[] | undefined
  attachments: AttachmentList
}
