import { Owner } from '~services/owner'
import { Address } from '~services/address'
import { SubmitListingType } from '~components/listingForm'

export type ListingCard = Pick<
  ListingDetails,
  | 'listing_id'
  | 'title'
  | 'general_listing_use'
  | 'listing_type'
  | 'address'
  | 'usable_floor_area'
  | 'price'
  | 'available_from'
  | 'owner_id'
  | 'attachments'
> & { gml_id: string } // 'gml_id' is needed to find the listing on the map

export type Ratings = 1 | 2 | 3 | 4 | 5

export enum ListingTypesEnum {
  buy = 'buy',
  rent = 'rent',
  all = 'all'
}

export enum ListingUsesEnum {
  house = 'house',
  apartment = 'apartment',
  shop = 'shop',
  office = 'office',
  senior_apartment = 'senior_apartment',
  storage_room = 'storage_room',
  production = 'production',
  trade = 'trade',
  garage = 'garage',
  parking_space = 'parking_space',
  vacant_plot = 'vacant_plot',
  gastronomy = 'gastronomy',
  other = 'other'
}

export enum UtilitiesEnum {
  water = 'water',
  sewage = 'sewage',
  electricity = 'electricity',
  gas = 'gas',
  phone = 'phone',
  cable = 'cable',
  fiber_internet = 'fiber_internet',
  district_heating = 'district_heating'
}

export enum MarketingMethodsEnum {
  none = 'none',
  agent_commissioned = 'agent_commissioned',
  direct_tenant_contact = 'direct_tenant_contact',
  newspaper_add = 'newspaper_add',
  bank_contact = 'bank_contact',
  digital_media = 'digital_media',
  other = 'other',
  not_applicable = 'not_applicable'
}

export enum SalesPlanningEnum {
  none = 'Keine',
  in_principle_yes = 'Grundsätzlich ja',
  yes_intentions_to_sell = 'Ja, Verkaufsabsichten',
  generally_no = 'Grundsätzlich nein',
  no_not_in_upcoming_5_years = 'Nein, nicht in den nächsten 5 Jahren'
}

export enum OwnerRelationshipsEnum {
  own_use = 'own_use',
  rented_out = 'rented_out',
  vacant = 'vacant'
}

export enum EnergyClassEnum {
  'A+' = 'A+',
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
  H = 'H'
}

export type EnergyCertificate = {
  label?: EnergyClassEnum
  issuing_date?: number
  energy_consumption?: number
}

export type Attachment = {
  attachment_id: number
  description: string
  name: string
  s3_presigned_url: string
}

export enum GeneralListingUseEnum {
  commercial = 'commercial',
  residential = 'residential',
  other = 'other',
  all = 'all'
}

export type ListingDetailMetadata = {
  comments?: string
  surroundings?: string
  appearance_comments?: string
  available_to?: Date
  supply_technology?: Ratings
  neighbourhood_quality?: Ratings
  accessibility?: Ratings
  frequency?: number
  competitors?: string
  interior_appearance?: Ratings
  regulations?: string
  vacant_since?: Date
  has_basement?: boolean
  has_attic?: boolean
  heating_type?: string
  internet_mbits?: number
  investment_details?: string
  main_remarketing_problems?: string
  neighbour_cooperation_details?: string
  req_improvements_commercial_use?: string
  furnished?: boolean
  consultation_needed?: boolean
  accessibility_comments?: string
  monument_status?: boolean
  construction_year?: number
}

export type ListingDetails = {
  title: string
  approval_status: string
  num_views?: number
  created_at: Date
  available_from?: Date
  listing_type: ListingTypesEnum
  listing_use: ListingUsesEnum
  general_listing_use?: GeneralListingUseEnum
  usable_floor_area: number
  price: number
  description: string
  energy_certificate: EnergyCertificate
  num_rooms?: number
  utilities: UtilitiesEnum[]
  prev_marketing_methods?: MarketingMethodsEnum[]
  property_sale_planned?: SalesPlanningEnum
  previous_listing_use?: ListingUsesEnum
  owner_relationship?: OwnerRelationshipsEnum
  floor?: number
  num_floors?: number
  service_costs?: number
  plot_area?: number
  renovation_year?: number
  additional_metadata: ListingDetailMetadata
  listing_id: number
  address_id: number
  owner_id: number
  address: Address
  owner: Owner
  attachments: Attachment[]
}

export enum ListingsApprovalStatusEnum {
  all = 'all',
  draft = 'draft',
  approved = 'approved',
  rejected = 'rejected',
  submitted = 'submitted',
  closed = 'closed'
}

export type ListingsStatusFilters = {
  address_id?: number
  owner_id?: number
  approval_status?: ListingsApprovalStatusEnum
}

export type UpdateListingStatus = {
  newStatus?: ListingsApprovalStatusEnum
}

export type UpdateListing = {
  listingId: string
  listingBody: SubmitListingType
}
