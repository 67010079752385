import { Box, Container } from '@mui/material'
import { useState, useEffect, useCallback } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import { MapSwitch } from '~components/mapButtons'
import { FilterPanel, FilterPanelInputValues } from '~components/filterPanel'

import { ListView, MapView } from '.'
import { FilterTypesEnum } from '~components/filterPanel'

export const Renting = () => {
  const { isListView } = useParams()
  const [viewType, setViewType] = useState<boolean>(isListView === 'true')
  const [limit] = useState<number>(6)
  const [offset] = useState<number>(0)
  const [previous] = useState<boolean>(false)
  const [filterValues, setFilterValues] = useState<FilterPanelInputValues>({})
  const navigate = useNavigate()

  useEffect(() => {
    setViewType(isListView === 'true')
  }, [isListView])

  const toggleViewType = () => {
    const newViewType = !viewType
    setViewType(newViewType)
    // Update URL path without reloading
    navigate(`/mieten/search/${newViewType}`)
  }

  const handleFiltering = useCallback(async (event: FilterPanelInputValues) => {
    setFilterValues({ ...event })
  }, []) // Empty dependency array since handleFiltering doesn't depend on any external variables

  return (
    <Container sx={{ position: 'relative', display: 'flex', gap: 2 }}>
      <Box
        id="sidePanel"
        component="aside"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          paddingRight: '1rem'
        }}
      >
        <MapSwitch selected={viewType} switchView={toggleViewType} />
        <FilterPanel handleFiltering={handleFiltering} category={FilterTypesEnum.listing} />
      </Box>

      <Box id="content" sx={{ flexGrow: 1 }} component="section">
        {viewType ? (
          <ListView pagination={{ limit, offset, previous }} listingFilters={filterValues} />
        ) : (
          <MapView locationFilters={filterValues} />
        )}
      </Box>
    </Container>
  )
}
