import useSWR from 'swr'
import { Owner } from '~services/owner/types'
import { fetcher, tokenMiddleware } from '~services/utils'

export const useOwner = (username: string | undefined) => {
  // eslint-disable-next-line
  const { data, isLoading, error, mutate } = useSWR<Owner>(
    [username ? `owners/${username}` : null],
    // eslint-disable-next-line
    ([url, method, headers, body]: [string, string, Record<string, string>, any]) =>
      fetcher(url, 'GET', headers, body),
    { use: [tokenMiddleware] }
  )

  return {
    owner: data,
    error,
    mutate
  }
}
