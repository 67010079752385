import { ChangeEvent, useState } from 'react'
import {
  Grid,
  Button,
  Box,
  Typography,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Stack,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Tooltip
} from '@mui/material'
import { Delete as DeleteIcon, Info as InfoIcon } from '@mui/icons-material'
import { useTheme } from '@mui/material/styles'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import { StyledRadio, StyledTextField } from '~components/input'
import { EnergyClassEnum } from '~services/listings'

import {
  VacancyDetailsThreeFormProps,
  FileType,
  stepThreeSchema,
  StepThreeSchemaType,
  Cropper
} from '.'

export const FormStepThree = ({
  onSubmit,
  activeStep,
  handleBack,
  prefilledData,
  attachments
}: VacancyDetailsThreeFormProps) => {
  const theme = useTheme()
  const { control, handleSubmit, watch, setValue } = useForm<StepThreeSchemaType>({
    resolver: zodResolver(stepThreeSchema),
    defaultValues: {
      images: []
    },
    mode: 'onBlur'
  })
  const images = watch('images')

  const [imageComplete, setImageComplete] = useState<boolean>(true)

  const renderDropDownItems = (label: string) => (
    <MenuItem key={label} value={label}>
      {label}
    </MenuItem>
  )

  const renderRatingRadioButtons = (rating: number) => (
    <FormControlLabel
      label={`${rating}`}
      key={rating}
      value={rating}
      control={
        <StyledRadio
          sx={{
            color: 'primary.main',
            '&.Mui-checked': {
              color: 'primary.dark'
            }
          }}
        />
      }
    />
  )

  const renderFileListItems = (key: string, file: File) => (
    <ListItem key={key} sx={{ display: 'flex' }}>
      <div style={{ flex: 1 }}>
        <ListItemText primary={file.name} />
      </div>
      <IconButton onClick={() => handleDeleteFile(key)}>
        <DeleteIcon sx={{ color: 'primary.light' }} />
      </IconButton>
    </ListItem>
  )

  // Create IDs for selected files and add to existing file list.
  const handleImageInput = (onChange: (value: FileType[]) => void) => (files: File[]) => {
    if (files) {
      const newFiles = files.map((file: File) => {
        const id = crypto.randomUUID()
        return { id, file } as FileType
      })
      images && onChange([...images, ...newFiles])
    }
  }

  const handleDeleteFile = (id: string) => {
    if (images) {
      setValue(
        'images',
        [...images].filter(o => o.id !== id)
      )
    }
  }

  const handleRatingChange =
    (onChange: (value: number) => void) => (e: ChangeEvent<HTMLInputElement>) => {
      const value = parseInt(e.target.value)
      onChange(value)
    }

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Grid container>
        <Grid container item xs={7} spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5" color="primary.dark" my={1}>
              Energieausweis
            </Typography>
          </Grid>
          <Grid item xs={12} md={7}>
            <Controller
              name="energyClass"
              control={control}
              defaultValue={prefilledData.energyClass}
              render={({ field }) => (
                <StyledTextField {...field} select size="small" variant="outlined">
                  {Object.keys(EnergyClassEnum).map(renderDropDownItems)}
                </StyledTextField>
              )}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <Controller
              name="energyConsumption"
              control={control}
              defaultValue={prefilledData.energyConsumption}
              render={({ field, fieldState: { error } }) => (
                <StyledTextField
                  {...field}
                  id="energyConsumption"
                  label="Bedarf (kWh/m²*a)"
                  variant="outlined"
                  error={Boolean(error)}
                  helperText={error?.message}
                  size="small"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={7}>
            <Controller
              name="heatingType"
              defaultValue={prefilledData.heatingType}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <StyledTextField
                  {...field}
                  name={field.name}
                  label="Heizungsart"
                  variant="outlined"
                  error={Boolean(error)}
                  helperText={error?.message}
                  size="small"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <Controller
              name="heatingIssueYear"
              control={control}
              defaultValue={prefilledData.heatingIssueYear}
              render={({ field, fieldState: { error } }) => (
                <StyledTextField
                  {...field}
                  id="heatingIssueYear"
                  label="Ausstellungsjahr"
                  variant="outlined"
                  error={Boolean(error)}
                  helperText={error?.message}
                  size="small"
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" color="primary.dark" my={1}>
              Städtebau Bewertung (1 - niedrige Qualität, 5 - hohe Qualität)
            </Typography>
          </Grid>

          <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h5" color="primary.main" display="inline">
              Inneres Erscheinungsbild Bewertung:
            </Typography>
            <Typography variant="h5" color="primary.main" display="inline">
              <Tooltip
                title={
                  <Box
                    sx={{
                      backgroundColor: 'white',
                      color: 'primary.main',
                      border: '.05rem solid',
                      borderColor: 'neutral.light',
                      borderRadius: '0.5rem',
                      padding: '.5rem'
                    }}
                  >
                    <Box sx={{ display: 'flex', p: '.25rem' }}>
                      <List>
                        <ListItem>
                          <ListItemText primary="5: 3fach-Verglasung (Fenster/Türen), Fußbodenheizung, Echtholzparkett oder Steinböden, Schreinereinbauküche, Einbruchsicherung" />
                        </ListItem>
                        <ListItem>
                          <ListItemText primary="3: Fliesen- oder Parkettboden, sauber/gereinigt, bezugsfertig, 2fach-Verglasung (Fenster/Türen)" />
                        </ListItem>
                        <ListItem>
                          <ListItemText primary="1: renovierungsbedürftig, mangelhafte oder fehlerhafte Ausstattung, zugige Fenster und Türen" />
                        </ListItem>
                      </List>
                    </Box>
                  </Box>
                }
              >
                <IconButton sx={{ py: 0 }}>
                  <InfoIcon style={theme.typography.h5} sx={{ color: 'primary.main' }} />
                </IconButton>
              </Tooltip>
            </Typography>
          </Grid>
          <Grid item xs={12} ml={1}>
            <Controller
              control={control}
              name="innerAppearanceRating"
              defaultValue={prefilledData.innerAppearanceRating}
              render={({ field }) => (
                <RadioGroup {...field} row onChange={handleRatingChange(field.onChange)}>
                  {[1, 2, 3, 4, 5].map(renderRatingRadioButtons)}

                  <FormControlLabel
                    label={`Nicht zutreffend`}
                    value={0}
                    control={
                      <StyledRadio
                        sx={{
                          color: 'primary.main',
                          '&.Mui-checked': {
                            color: 'primary.dark'
                          }
                        }}
                      />
                    }
                  />
                </RadioGroup>
              )}
            />
          </Grid>

          <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h5" color="primary.main" display="inline">
              Äußeres Erscheinungsbild Bewertung:
            </Typography>

            <Typography variant="h5" color="primary.main" display="inline">
              <Tooltip
                title={
                  <Box
                    sx={{
                      backgroundColor: 'white',
                      color: 'primary.main',
                      border: '.05rem solid',
                      borderColor: 'neutral.light',
                      borderRadius: '0.5rem',
                      padding: '.5rem'
                    }}
                  >
                    <Box sx={{ display: 'flex', p: '.25rem' }}>
                      <List>
                        <ListItem>
                          <ListItemText primary="5: neu/neuwertig bzw. frisch renoviert" />
                        </ListItem>
                        <ListItem>
                          <ListItemText primary="3: Gebäudehülle gepflegt und intakt, nicht renovierungsbedürftiger Zustand " />
                        </ListItem>
                        <ListItem>
                          <ListItemText primary="1: renovierungsbedürftig, mangelhafte Gebäudehülle" />
                        </ListItem>
                      </List>
                    </Box>
                  </Box>
                }
              >
                <IconButton sx={{ py: 0 }}>
                  <InfoIcon style={theme.typography.h5} sx={{ color: 'primary.main' }} />
                </IconButton>
              </Tooltip>
            </Typography>
          </Grid>
          <Grid item xs={12} ml={1}>
            <Controller
              control={control}
              name="outerAppearanceRating"
              defaultValue={prefilledData.outerAppearanceRating}
              render={({ field }) => (
                <RadioGroup {...field} row onChange={handleRatingChange(field.onChange)}>
                  {[1, 2, 3, 4, 5].map(renderRatingRadioButtons)}
                  <FormControlLabel
                    label={`Nicht zutreffend`}
                    value={0}
                    control={
                      <StyledRadio
                        sx={{
                          color: 'primary.main',
                          '&.Mui-checked': {
                            color: 'primary.dark'
                          }
                        }}
                      />
                    }
                  />
                </RadioGroup>
              )}
            />
          </Grid>

          <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h5" color="primary.main" display="inline">
              Versorgungstechnik Bewertung:
            </Typography>

            <Typography variant="h5" color="primary.main" display="inline">
              <Tooltip
                title={
                  <Box
                    sx={{
                      backgroundColor: 'white',
                      color: 'primary.main',
                      border: '.05rem solid',
                      borderColor: 'neutral.light',
                      borderRadius: '0.5rem',
                      padding: '.5rem'
                    }}
                  >
                    <Box sx={{ display: 'flex', p: '.25rem' }}>
                      <List>
                        <ListItem>
                          <ListItemText primary="5: neuester Technikstand mit regenerativer Energie, wie bsp. Photovoltaikanlage, Solarthermie, Heizung neu bzw. max. 3 Jahre alt, Brauchwasseranlage" />
                        </ListItem>
                        <ListItem>
                          <ListItemText primary="3: Versorgungstechnik stetig gewartet, gut funktionierend, zwischen 10 und 20 Jahre alt" />
                        </ListItem>
                        <ListItem>
                          <ListItemText primary="1: Versorgungsleitungen für Strom/Wasser älter als 30 Jahre, keine Heizung oder älter als 30 Jahre, Nachtspeicherofen" />
                        </ListItem>
                      </List>
                    </Box>
                  </Box>
                }
              >
                <IconButton sx={{ py: 0 }}>
                  <InfoIcon style={theme.typography.h5} sx={{ color: 'primary.main' }} />
                </IconButton>
              </Tooltip>
            </Typography>
          </Grid>
          <Grid item xs={12} ml={1}>
            <Controller
              control={control}
              name="supplyTechnologyRating"
              defaultValue={prefilledData.supplyTechnologyRating}
              render={({ field }) => (
                <RadioGroup {...field} row onChange={handleRatingChange(field.onChange)}>
                  {[1, 2, 3, 4, 5].map(renderRatingRadioButtons)}
                  <FormControlLabel
                    label={`Nicht zutreffend`}
                    value={0}
                    control={
                      <StyledRadio
                        sx={{
                          color: 'primary.main',
                          '&.Mui-checked': {
                            color: 'primary.dark'
                          }
                        }}
                      />
                    }
                  />
                </RadioGroup>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="appearanceAdditionalInformation"
              defaultValue={prefilledData.appearanceAdditionalInformation}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <StyledTextField
                  {...field}
                  name={field.name}
                  label="Freitext weitere Informationen - max. 800 Zeichen"
                  variant="outlined"
                  error={Boolean(error)}
                  helperText={error?.message}
                  size="small"
                  multiline
                  rows={6}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="internetMbits"
              control={control}
              defaultValue={prefilledData.internetMbits}
              render={({ field, fieldState: { error } }) => (
                <StyledTextField
                  {...field}
                  id="internetMbits"
                  label="Internet Geschwindigkeit (mbits)"
                  variant="outlined"
                  error={Boolean(error)}
                  helperText={error?.message}
                  size="small"
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" color="primary.dark" my={1}>
              Lage Bewertung (1 - mangelhaft, 5 - sehr gut)
            </Typography>
          </Grid>

          <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h5" color="primary.main" display="inline">
              Erreichbarkeit Pkw und ÖPNV:
            </Typography>

            <Typography variant="h5" color="primary.main" display="inline">
              <Tooltip
                title={
                  <Box
                    sx={{
                      backgroundColor: 'white',
                      color: 'primary.main',
                      border: '.05rem solid',
                      borderColor: 'neutral.light',
                      borderRadius: '0.5rem',
                      padding: '.5rem'
                    }}
                  >
                    <Box sx={{ display: 'flex', p: '.25rem' }}>
                      <List>
                        <ListItem>
                          <ListItemText primary="5: eigener Stellplatz/Garage, Tiefgarage, E-Lade-Möglichkeit, Car-Sharing, ÖPNV-Haltestelle mit hoher Taktfrequenz fußläufig in max. 5 min. erreichbar" />
                        </ListItem>
                        <ListItem>
                          <ListItemText primary="3: fest zugewiesener Stellplatz in der unmittelbaren Nähe, ÖPNV-Anbindung täglich erreichbar" />
                        </ListItem>
                        <ListItem>
                          <ListItemText primary="1: kein eigener Stellplatz, keine eigene Zufahrt, keine Parkmöglichkeit im Umkreis < 500 m, kein ÖPNV-Anschluss" />
                        </ListItem>
                      </List>
                    </Box>
                  </Box>
                }
              >
                <IconButton sx={{ py: 0 }}>
                  <InfoIcon style={theme.typography.h5} sx={{ color: 'primary.main' }} />
                </IconButton>
              </Tooltip>
            </Typography>
          </Grid>
          <Grid item xs={12} ml={1}>
            <Controller
              control={control}
              defaultValue={prefilledData.accessibilityRating}
              name="accessibilityRating"
              render={({ field }) => (
                <RadioGroup {...field} row onChange={e => field.onChange(parseInt(e.target.value))}>
                  {[1, 2, 3, 4, 5].map(renderRatingRadioButtons)}
                </RadioGroup>
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="accessibilityExplanation"
              control={control}
              defaultValue={prefilledData.accessibilityExplanation}
              render={({ field, fieldState: { error } }) => (
                <StyledTextField
                  {...field}
                  label="Umgebungspotential - max. 800 Zeichen"
                  variant="outlined"
                  error={Boolean(error)}
                  helperText={error?.message}
                  size="small"
                  multiline
                  rows={6}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" color="primary.dark" my={1}>
              Sonstige Information
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="other"
              control={control}
              defaultValue={prefilledData.other}
              render={({ field, fieldState: { error } }) => (
                <StyledTextField
                  {...field}
                  name={field.name}
                  label="Sonstiges - max. 800 Zeichen"
                  variant="outlined"
                  error={Boolean(error)}
                  helperText={error?.message}
                  size="small"
                  multiline
                  rows={6}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" color="primary.main">
              Benötigen Sie ein Beratungsgespräch mit unserem Spezialisten?
            </Typography>
          </Grid>
          <Grid xs={12} item ml={1}>
            <Controller
              control={control}
              name="wishesConsultation"
              defaultValue={prefilledData.wishesConsultation}
              render={({ field }) => (
                <RadioGroup {...field} row>
                  <FormControlLabel
                    value="true"
                    control={
                      <StyledRadio
                        sx={{
                          color: 'primary.main',
                          '&.Mui-checked': {
                            color: 'primary.dark'
                          }
                        }}
                      />
                    }
                    label="Ja"
                  />
                  <FormControlLabel
                    value=""
                    control={
                      <StyledRadio
                        sx={{
                          color: 'primary.main',
                          '&.Mui-checked': {
                            color: 'primary.dark'
                          }
                        }}
                      />
                    }
                    label="Nein"
                  />
                </RadioGroup>
              )}
            />
          </Grid>
        </Grid>
        <Grid item xs={5}>
          <Stack mx={10}>
            <Controller
              control={control}
              name="images"
              render={({ fieldState: { error }, field: { value, onChange } }) => {
                return (
                  <>
                    <Cropper
                      onUpload={handleImageInput(onChange)}
                      setImageComplete={setImageComplete}
                      existingImages={prefilledData.images}
                      attachments={attachments}
                    />
                    <Typography color="error" mt={2} textAlign="center">
                      {error?.message}
                    </Typography>
                    <Typography mt={2}>Unterstützte Dateiformate: JPG, JPEG, PNG</Typography>

                    <Stack>
                      <Typography mt={2}>Hochgeladene Dateien:</Typography>
                      <List>
                        {value
                          ? value.map((file: { id: string; file: File }) =>
                              renderFileListItems(file.id, file.file)
                            )
                          : ''}
                      </List>
                    </Stack>
                  </>
                )
              }}
            />
          </Stack>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Box mt="1.5rem" sx={{ display: 'flex', gap: 8 }}>
          <Button
            variant="contained"
            onClick={handleBack}
            sx={{ visibility: activeStep > 0 ? 'visible' : 'hidden', width: '13rem' }}
          >
            Zurück
          </Button>
          <Button
            variant="contained"
            type="submit"
            sx={{ width: '13rem' }}
            color={activeStep === 2 ? 'success' : 'primary'}
            disabled={!imageComplete} // Disable the button if imageComplete is false
          >
            {activeStep === 2 ? 'Absenden' : 'Nächster Schritt'}
          </Button>
          {!imageComplete && (
            <Typography variant="body2" color="error">
              Bitte laden Sie zuerst das Bild hoch, bevor Sie fortfahren.
            </Typography>
          )}
        </Box>
      </Grid>
    </Box>
  )
}
