import { useEffect, useState } from 'react'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { UserRoleEnum } from '~services/owner'

export const useUser = () => {
  const { user } = useAuthenticator(context => [context.user])
  const [userRole, setUserRole] = useState<UserRoleEnum>(UserRoleEnum.Read)
  const [jwtToken, setJwtToken] = useState<string | undefined>()

  useEffect(() => {
    const fetchUserInformation = async () => {
      try {
        user &&
          // The types of amplify are messed up. There has been an open issue about it since 2020
          // https://github.com/aws-amplify/amplify-js/issues/4927
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          user.getSession((error: Error, session: any) => {
            if (error) {
              console.error('Error getting user session:', error)
              return
            }

            const userGroups = session?.accessToken?.payload['cognito:groups']

            if (userGroups && userGroups.length > 0) {
              setUserRole(userGroups[0])
            } else {
              console.warn('User does not belong to any groups')
            }

            const idToken = session.getIdToken().jwtToken
            setJwtToken(idToken)
          })
      } catch (error) {
        console.log('Error retrieving user', error)
      }
    }

    if (!jwtToken) {
      fetchUserInformation()
    }
  }, [user, jwtToken])

  return {
    userRole,
    jwtToken
  }
}
