import React, { SyntheticEvent, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useAuthenticator } from '@aws-amplify/ui-react'
import {
  Tabs,
  Tab,
  Box,
  Typography,
  ClickAwayListener,
  Popover,
  List,
  ListItem,
  Button
} from '@mui/material'
import { AccountCircle as AccountCircleIcon } from '@mui/icons-material'

import { AccountMenu } from '.'
import { VoucherTypesEnum } from '~services/vouchers/types'
import { useFilterContext } from '~components/filterPanel/FilterContext'

export const Navbar = () => {
  const { pathname } = useLocation()
  const [activeTab, setActiveTab] = useState<number>(1)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [submenuAnchorEl, setSubmenuAnchorEl] = useState<null | HTMLElement>(null)
  const [gutscheinSubmenuAnchorEl, setGutscheinSubmenuAnchorEl] = useState<null | HTMLElement>(null)
  const isAccountMenuOpen = Boolean(anchorEl)
  const { user } = useAuthenticator(context => [context.user])

  const { updateFilters } = useFilterContext()

  const handleGutscheinSubmenuClick = (voucherType: VoucherTypesEnum) => {
    // Trigger filter change based on the button click
    const filtersForButton = {
      gutscheine: voucherType
    }

    // Update the filters using the handleFiltering prop
    updateFilters(filtersForButton)
    closeGutscheinSubmenu()
  }

  /**
   * Set the active tab based on the URL rather than user interaction.
   */
  React.useEffect(() => {
    pathname.includes('/mieten')
      ? setActiveTab(1)
      : pathname.includes('/stadtgutschein')
      ? setActiveTab(2)
      : setActiveTab(3)
  }, [pathname])

  /**
   * Sets the new tab.
   * In case of the last tab (account), the "cursor" is going to stay on the previously active tab
   * @param event - the click event
   * @param newTab - newly selected tabs number
   */
  const handleTabChange = (event: SyntheticEvent, newTab: number) =>
    setActiveTab(prevTab => (newTab === 3 ? prevTab : newTab))

  const openAccountMenu = (event: SyntheticEvent<HTMLElement>) => setAnchorEl(event.currentTarget)

  const closeAccountMenu = () => setAnchorEl(null)

  const openSubmenu = (event: SyntheticEvent<HTMLElement>) =>
    setSubmenuAnchorEl(event.currentTarget)

  const closeLeerstandSubmenu = () => setSubmenuAnchorEl(null)

  const openGutscheinSubmenu = (event: SyntheticEvent<HTMLElement>) =>
    setGutscheinSubmenuAnchorEl(event.currentTarget)

  const closeGutscheinSubmenu = () => setGutscheinSubmenuAnchorEl(null)

  const handleLeerstandSubMenuClick = () => {
    closeLeerstandSubmenu()
  }

  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: 'white'
      }}
    >
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        aria-label="navigation bar"
        id="tabsComponent"
        sx={{
          '& .MuiTabs-indicator': { display: 'none' },
          '& .MuiTabs-flexContainer': { gap: 1 },
          '& .MuiTab-root': { color: 'primary.main', textTransform: 'unset' },
          '& .MuiTab-root.Mui-selected': { color: 'primary.dark' }
        }}
      >
        <Tab
          label={
            <Typography variant="h5" onClick={openSubmenu}>
              Immobilienleerstand
            </Typography>
          }
          sx={{ paddingLeft: 0, paddingBottom: 0, minHeight: 'auto' }}
          value={1}
        />
        <Tab
          label={
            <Typography variant="h5" onClick={openGutscheinSubmenu}>
              Stadtgutschein / Stadtguthaben
            </Typography>
          }
          sx={{ paddingBottom: 0, minHeight: 'auto' }}
          value={2}
        />
        <Tab
          key="accountMenuTab"
          label={
            <ClickAwayListener onClickAway={closeAccountMenu}>
              {user ? (
                <Typography variant="h5" onClick={openAccountMenu}>
                  {user.username}
                </Typography>
              ) : (
                <Typography variant="h5" onClick={openAccountMenu}>
                  Mein Profil
                </Typography>
              )}
            </ClickAwayListener>
          }
          value={3}
          aria-controls={isAccountMenuOpen ? 'accountMenu' : undefined}
          sx={{ marginLeft: 'auto', paddingRight: 0, minHeight: 'auto' }}
          icon={<AccountCircleIcon />}
          iconPosition="end"
        />
      </Tabs>

      <AccountMenu
        key="accountMenu"
        isOpen={isAccountMenuOpen}
        anchor={anchorEl}
        closeMenu={closeAccountMenu}
      />

      <Popover
        open={Boolean(submenuAnchorEl)}
        anchorEl={submenuAnchorEl}
        onClose={closeLeerstandSubmenu}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <List>
          <ListItem>
            <Button component={Link} to={'/'} onClick={() => handleLeerstandSubMenuClick()}>
              Immobilie suchen
            </Button>
          </ListItem>
          <ListItem>
            <Button component={Link} to={'/info'} onClick={() => handleLeerstandSubMenuClick()}>
              Immobilie anbieten
            </Button>
          </ListItem>
        </List>
      </Popover>

      {/* Additional Submenu for Stadtgutschein / Stadtguthaben */}
      <Popover
        open={Boolean(gutscheinSubmenuAnchorEl)}
        anchorEl={gutscheinSubmenuAnchorEl}
        onClose={closeGutscheinSubmenu}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <List>
          <ListItem>
            <Button
              component={Link}
              to={'/stadtgutschein'}
              onClick={() => handleGutscheinSubmenuClick(VoucherTypesEnum.accepts)}
            >
              Akzeptanzstellen
            </Button>
          </ListItem>
          <ListItem>
            <Button
              component={Link}
              to={'/stadtgutschein'}
              onClick={() => handleGutscheinSubmenuClick(VoucherTypesEnum.sells)}
            >
              Verkaufsstellen
            </Button>
          </ListItem>
          <ListItem>
            <Button component={Link} to={'/stadtgutschein'} onClick={closeGutscheinSubmenu}>
              {/*to={'/online-kaufen'} - replace once site for buying vouchers from Stadtguthaben is ready*/}
              Online kaufen
            </Button>
          </ListItem>
          <ListItem>
            <Button component={Link} to={'/stadtgutschein'} onClick={closeGutscheinSubmenu}>
              {/*to={'/guthabenabfrage'} - replace once site for checking credit from Stadtguthaben is ready */}
              Guthabenabfrage
            </Button>
          </ListItem>
        </List>
      </Popover>
    </Box>
  )
}
