import { Box, Container, Typography, Grid, Button } from '@mui/material'
import { useState, useCallback } from 'react'

import Link from '@mui/material/Link'
import CheckIcon from '@mui/icons-material/Check'

import { MapView } from '.'
import { FilterPanel, FilterPanelInputValues, FilterTypesEnum } from '~components/filterPanel'

export const VoucherSystem = () => {
  const [filterValues, setFilterValues] = useState<FilterPanelInputValues>({})

  const handleFiltering = useCallback(async (event: FilterPanelInputValues) => {
    setFilterValues({ ...event })
  }, []) // Empty dependency array since handleFiltering doesn't depend on any external variables

  return (
    <Container sx={{ position: 'relative', display: 'flex', gap: 2 }}>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          paddingBottom: '1rem',
          paddingRight: '1rem',
          width: '20%'
        }}
      >
        <Box style={{ marginBottom: 10 }}>
          <FilterPanel handleFiltering={handleFiltering} category={FilterTypesEnum.voucher} />
        </Box>
        <Box component="aside">
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              gap: 2,
              borderRadius: '.75rem',
              padding: '1rem',
              backgroundColor: 'white',
              border: '1px solid',
              borderColor: 'neutral.light',
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
            }}
          >
            <Typography variant="h3">Stadtguthaben</Typography>
            <Typography>
              Willkommen zur Übersicht der Stadtguthaben-Verkaufsstellen. Dies ist der Link zur
              Stadtguthaben-Seite für Traunreut.
            </Typography>
            <Link href="https://www.demostadt-stadtgutschein.de/" target="_blank">
              <Button sx={{ width: '100%' }} variant="outlined">
                Stadtguthaben-Seite
              </Button>
            </Link>
            <Typography>
              Klicken Sie auf einen der Marker um die Details der Shops anzuzeigen.
            </Typography>
            <Typography>Sie erhalten ebenfalls Informationen, ob der Shop</Typography>
            <Grid container direction="row" gap={2} alignItems="center">
              <CheckIcon sx={{ color: 'primary.dark' }} /> Gutscheine akzeptiert
              <CheckIcon sx={{ color: 'primary.dark' }} /> Gutscheine verkauft
            </Grid>
          </Box>
        </Box>
      </Box>
      <Box id="content" sx={{ flexGrow: 1 }} component="section">
        <MapView locationFilters={filterValues} />
      </Box>
    </Container>
  )
}
