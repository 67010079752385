import { useState } from 'react'
import { Grid, Typography } from '@mui/material'
import dayjs from 'dayjs'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { useLocation } from 'react-router-dom'

import {
  FormDataType,
  FormStepOne,
  FormStepThree,
  FormStepTwo,
  FormSuccess,
  LeaseAgreementEnum,
  SubmitListingType,
  VacancyDetailsOneFormType,
  VacancyDetailsThreeFormType,
  VacancyDetailsTwoFormType
} from '~components/listingForm'
import {
  EnergyClassEnum,
  GeneralListingUseEnum,
  ListingTypesEnum,
  ListingUsesEnum,
  MarketingMethodsEnum,
  OwnerRelationshipsEnum,
  useListing,
  useListingCreation,
  useUpdateListing
} from '~services/listings'
import { Address } from '~services/address'
import { useOwner, useUser } from '~services/owner'

export const Letting = () => {
  const { state } = useLocation()
  const isEditMode = !!state?.propertyId
  const [activeStep, setActiveStep] = useState(0)
  const { user } = useAuthenticator(context => [context.user])
  const { owner } = useOwner(user.username)
  const { listing } = useListing(state?.propertyId)
  const jwtToken = useUser().jwtToken

  const [completeFormData, setFormData] = useState<FormDataType>({
    step1: {
      title: listing?.title || '',
      address:
        listing?.address?.streetname_number && listing?.address_id
          ? ({
              streetname_number: listing?.address?.streetname_number,
              address_id: Number(listing?.address_id)
            } as Address)
          : ({ streetname_number: '', address_id: 0 } as Address),
      transactionType: listing?.listing_type || ListingTypesEnum.rent,
      leaseAgreement: listing?.additional_metadata?.available_to
        ? LeaseAgreementEnum.limited
        : LeaseAgreementEnum.unlimited,
      leaseStart: dayjs(listing?.available_from) || dayjs(new Date()),
      leaseEnd: dayjs(new Date()),
      price: listing?.price ? String(listing.price) : '',
      serviceCosts: listing?.service_costs ? String(listing.service_costs) : '',
      retailSpaceSize: listing?.usable_floor_area ? String(listing.usable_floor_area) : '',
      propertySize: listing?.plot_area ? String(listing.plot_area) : '',
      propertyType: listing?.general_listing_use || GeneralListingUseEnum.other,
      propertyStatus: listing?.owner_relationship || OwnerRelationshipsEnum.vacant,
      propertyUsage: listing?.listing_use || ListingUsesEnum.other
    },
    step2: {
      description: listing?.description || '',
      numRooms: listing?.num_rooms ? String(listing.num_rooms) : '',
      floor: listing?.floor ? String(listing.floor) : '',
      numFloors: listing?.num_floors ? String(listing.num_floors) : '',
      utilities: listing?.utilities || [],
      constructionYear: listing?.additional_metadata?.construction_year
        ? String(listing?.additional_metadata?.construction_year)
        : '',
      renovationYear: listing?.renovation_year ? String(listing.renovation_year) : '',
      monumentStatus:
        listing?.additional_metadata?.monument_status === true
          ? 'true'
          : listing?.additional_metadata?.monument_status === false
          ? 'false'
          : 'Nicht zutreffend',
      hasBasement:
        listing?.additional_metadata?.has_basement === true
          ? 'true'
          : listing?.additional_metadata?.has_basement === false
          ? 'false'
          : 'Nicht zutreffend',
      hasAttic:
        listing?.additional_metadata?.has_attic === true
          ? 'true'
          : listing?.additional_metadata?.has_attic === false
          ? 'false'
          : 'Nicht zutreffend',
      furnished:
        listing?.additional_metadata?.furnished === true
          ? 'true'
          : listing?.additional_metadata?.furnished === false
          ? 'false'
          : 'Nicht zutreffend',
      vacantSince: dayjs(listing?.additional_metadata?.vacant_since) || '',
      prevMarketingMethods:
        listing?.prev_marketing_methods?.[0] || MarketingMethodsEnum.not_applicable,
      mainRemarketingProblems: listing?.additional_metadata?.main_remarketing_problems || '',
      investmentDetails: listing?.additional_metadata.investment_details || '',
      reqImprovementsCommercialUse:
        listing?.additional_metadata?.req_improvements_commercial_use || '',
      neighbourCooperationDetails: listing?.additional_metadata?.neighbour_cooperation_details || ''
    },
    step3: {
      energyConsumption: listing?.energy_certificate?.energy_consumption
        ? String(listing.energy_certificate.energy_consumption)
        : '',
      energyClass: listing?.energy_certificate?.label || EnergyClassEnum['A+'],
      heatingType: listing?.additional_metadata?.heating_type || '',
      heatingIssueYear: dayjs(listing?.energy_certificate?.issuing_date).year().toString() || '',
      innerAppearanceRating: listing?.additional_metadata?.interior_appearance || undefined,
      outerAppearanceRating: listing?.additional_metadata?.neighbourhood_quality || undefined,
      supplyTechnologyRating: listing?.additional_metadata?.supply_technology || undefined,
      internetMbits: listing?.additional_metadata?.internet_mbits
        ? String(listing.additional_metadata.internet_mbits)
        : '',
      appearanceAdditionalInformation: listing?.additional_metadata?.appearance_comments || '',
      accessibilityRating: listing?.additional_metadata?.accessibility || 1,
      accessibilityExplanation: listing?.additional_metadata?.accessibility_comments || '',
      other: listing?.additional_metadata?.comments || '',
      wishesConsultation: listing?.additional_metadata?.consultation_needed
        ? 'true'
        : 'false' || '',
      images:
        listing?.attachments?.map(att => ({
          file: {
            name: att.name
          } as File,
          id: String(att.attachment_id)
        })) || []
    }
  })

  const [_images, setImages] = useState<File[] | null>(null)
  // TODO key or ownerid is missing, possibly because of faulty useOwner?
  const { trigger: createListing } = useListingCreation(_images)
  const { trigger: updateListingTrigger } = useUpdateListing(state?.propertyId, _images)

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleSubmit = (
    formData: VacancyDetailsOneFormType | VacancyDetailsTwoFormType | VacancyDetailsThreeFormType
  ) => {
    setFormData(state => ({
      ...state, // gets replaced by all key-value pairs from obj
      [`step${activeStep + 1}`]: formData // updates key [name] with new value
    }))
    handleNext()
  }

  const handleSubmitForm = async (
    formData: VacancyDetailsOneFormType | VacancyDetailsTwoFormType | VacancyDetailsThreeFormType
  ) => {
    const data = formData as VacancyDetailsThreeFormType
    setFormData(state => ({
      ...state,
      [`step${activeStep + 1}`]: data
    }))
    const _listing: SubmitListingType = mapFormData({
      ...completeFormData,
      step3: data as VacancyDetailsThreeFormType
    })

    setImages(data.images ? data.images.map(o => o.file) : null)

    if (isEditMode) {
      //TODO how to handle if token is not there?
      // update listing
      await updateListingTrigger({
        listingId: state.propertyId,
        listingBody: _listing,
        authToken: jwtToken
      })
    } else {
      // create listing
      await createListing({ submitListing: _listing, authToken: jwtToken })
    }

    handleNext()
  }

  // Cast the form to the object the BE will accept.
  const mapFormData = (formData: FormDataType) => {
    return {
      title: formData.step1.title,
      description: formData.step2.description,
      available_from:
        formData.step1.transactionType === ListingTypesEnum.buy
          ? undefined
          : formData.step1.leaseStart.toDate(),
      listing_type: formData.step1.transactionType,
      listing_use: formData.step1.propertyUsage,
      usable_floor_area: parseFloat(formData.step1.retailSpaceSize),
      price: parseFloat(formData.step1.price),
      num_rooms: formData.step2.numRooms ? parseFloat(formData.step2.numRooms) : undefined,
      floor: formData.step2.floor ? parseFloat(formData.step2.floor) : undefined,
      num_floors: formData.step2.numFloors ? parseFloat(formData.step2.numFloors) : undefined,
      utilities:
        formData.step2.utilities && formData.step2.utilities.length > 0
          ? formData.step2.utilities
          : [],
      renovation_year: formData.step2.renovationYear
        ? parseFloat(formData.step2.renovationYear)
        : undefined,
      prev_marketing_methods:
        formData.step2.prevMarketingMethods &&
        formData.step2.prevMarketingMethods !== MarketingMethodsEnum.not_applicable
          ? [formData.step2.prevMarketingMethods]
          : [],
      energy_certificate: {
        label: formData.step3.energyClass,
        issuing_date: formData.step3.heatingIssueYear
          ? new Date(formData.step3.heatingIssueYear).getFullYear()
          : undefined,
        energy_consumption: formData.step3.energyConsumption
          ? parseFloat(formData.step3.energyConsumption)
          : undefined
      },
      owner_relationship: formData.step1.propertyStatus,
      service_costs: formData.step1.serviceCosts
        ? parseFloat(formData.step1.serviceCosts)
        : undefined,
      plot_area: parseFloat(formData.step1.propertySize),
      general_listing_use: formData.step1.propertyType,
      additional_metadata: {
        available_to:
          formData.step1.transactionType === ListingTypesEnum.buy ||
          formData.step1.leaseAgreement === LeaseAgreementEnum.unlimited
            ? undefined
            : formData.step1.leaseEnd?.toDate(),
        vacant_since: formData.step2.vacantSince?.toDate(),
        monument_status:
          formData.step2.monumentStatus === 'Nicht zutreffend'
            ? undefined
            : Boolean(formData.step2.monumentStatus),
        has_basement:
          formData.step2.hasBasement === 'Nicht zutreffend'
            ? undefined
            : Boolean(formData.step2.hasBasement),
        has_attic:
          formData.step2.hasAttic === 'Nicht zutreffend'
            ? undefined
            : Boolean(formData.step2.hasAttic),
        furnished:
          formData.step2.furnished === 'Nicht zutreffend'
            ? undefined
            : Boolean(formData.step2.furnished),
        main_remarketing_problems: formData.step2.mainRemarketingProblems,
        investment_details: formData.step2.investmentDetails,
        req_improvements_commercial_use: formData.step2.reqImprovementsCommercialUse,
        neighbour_cooperation_details: formData.step2.neighbourCooperationDetails,
        interior_appearance: formData.step3.innerAppearanceRating
          ? formData.step3.innerAppearanceRating
          : undefined,
        neighbourhood_quality: formData.step3.outerAppearanceRating
          ? formData.step3.outerAppearanceRating
          : undefined,
        supply_technology: formData.step3.supplyTechnologyRating
          ? formData.step3.supplyTechnologyRating
          : undefined,
        internet_mbits: formData.step3.internetMbits
          ? parseFloat(formData.step3.internetMbits)
          : undefined,
        appearance_comments: formData.step3.appearanceAdditionalInformation,
        accessibility: formData.step3.accessibilityRating,
        accessibility_comments: formData.step3.accessibilityExplanation,
        comments: formData.step3.other,
        consultation_needed: Boolean(formData.step3.wishesConsultation),
        heating_type: formData.step3.heatingType,
        construction_year: formData.step2.constructionYear
          ? parseFloat(formData.step2.constructionYear)
          : undefined
      },
      address_id: formData.step1.address.address_id,
      owner_id: owner ? owner.owner_id : NaN
    }
  }

  return (
    <>
      <Typography variant="h4" color="primary.dark" mb={2}>
        {isEditMode ? 'Bearbeitung der Anzeige' : 'Neue Anzeige'}
      </Typography>
      {activeStep < 1 && (
        <Typography textAlign="left" variant="h3" color="primary.dark">
          Schritt {activeStep + 1} von 3
        </Typography>
      )}
      <Grid item xs={12} mt={3}>
        {activeStep === 0 && (
          <FormStepOne
            onSubmit={handleSubmit}
            activeStep={activeStep}
            handleBack={handleBack}
            prefilledData={completeFormData.step1}
            isEditMode={isEditMode}
          />
        )}
        {activeStep === 1 && (
          <FormStepTwo
            onSubmit={handleSubmit}
            activeStep={activeStep}
            handleBack={handleBack}
            prefilledData={completeFormData.step2}
          />
        )}
        {activeStep === 2 && (
          <FormStepThree
            onSubmit={handleSubmitForm}
            activeStep={activeStep}
            handleBack={handleBack}
            prefilledData={completeFormData.step3}
            attachments={listing?.attachments}
          />
        )}
        {activeStep === 3 && <FormSuccess />}
      </Grid>
    </>
  )
}
