import { Box, Button, Card, Grid, Paper, Typography, Chip } from '@mui/material'
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Map as MapIcon
} from '@mui/icons-material'
import { useTheme } from '@mui/material/styles'

import { OrientationEnum } from '~types/ui'

import { CardComponentProps, Carousel } from '.'

export const CardComponent = ({
  orientation,
  title,
  labels,
  images,
  actionBtnText,
  actionBtnCallback,
  showMultiple,
  total,
  current,
  prevCard,
  nextCard,
  showMapViewButton,
  children,
  closeWindow,
  popup,
  cardColor
}: CardComponentProps) => {
  const theme = useTheme()

  const renderLabels = (label: string) => (
    <Chip
      key={label}
      label={label}
      variant="outlined"
      size="small"
      sx={{
        color: 'primary.main',
        border: 'solid 1px',
        borderColor: 'primary.main',
        borderRadius: '.5rem'
      }}
    />
  )

  const paperStyling = {
    maxWidth: orientation === OrientationEnum.horizontal ? '908px' : '380px',
    border: 'solid 1px',
    overflow: 'hidden',
    borderColor: 'neutral.light',
    borderRadius: '.75rem',
    marginBottom: '2rem',
    backgroundColor: cardColor
  }

  const sliderStyling = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '1rem'
  }

  return (
    <Paper sx={paperStyling} elevation={8}>
      <Card
        sx={{
          position: 'relative',
          padding: '2rem',
          borderRadius: '.75rem',
          display: 'flex',
          flexDirection: orientation === OrientationEnum.horizontal ? 'row' : 'column',
          backgroundColor: cardColor,
          zIndex: 999
        }}
      >
        {popup ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'right',
              cursor: 'pointer',
              paddingBottom: 2,
              color: 'primary.dark',
              zIndex: 2,
              fontSize: 20,
              fontWeight: 'bold'
            }}
            onClick={closeWindow}
          >
            x
          </Box>
        ) : null}
        <Grid container direction="column" spacing={4}>
          {/*----- Card title -----*/}
          <Grid item>
            <Typography
              variant="h3"
              color="primary.dark"
              sx={{ marginBottom: '1rem', maxWidth: 500, wordWrap: 'break-word' }}
            >
              {title}
            </Typography>

            {/*----- Card tags -----*/}
            <Grid container gap={2} mb="1rem">
              <Grid item xs={6} display="flex" gap={1}>
                {labels && labels.length > 0 && labels.map(renderLabels)}
              </Grid>

              {/*----- Go to map view button -----*/}
              {orientation === OrientationEnum.horizontal && showMapViewButton && (
                <Grid item xs={5} display="flex">
                  <Chip
                    label={'Auf Karte zeigen'}
                    icon={<MapIcon sx={{ fill: theme.palette.primary.dark }} fontSize="small" />}
                    variant="outlined"
                    size="small"
                    sx={{
                      color: 'primary.dark',
                      border: 'solid 1px',
                      borderColor: 'primary.dark',
                      borderRadius: '.5rem'
                    }}
                  />
                </Grid>
              )}
            </Grid>

            {/*----- Card Content -----*/}
            <Box display="flex" flexWrap="wrap">
              {children}
            </Box>
          </Grid>
        </Grid>

        {/*----- Carousel, grouped together with buttons -----*/}
        <Grid item>
          <Grid container justifyContent="center" display="flex" maxWidth="400px">
            {/*----- Image carousel -----*/}
            {images && images.length > 0 && <Carousel images={images} />}

            {/*----- Action button -----*/}
            <Grid item mt={2} sx={{ width: '100%' }}>
              <Button variant="contained" sx={{ width: '100%' }} onClick={actionBtnCallback}>
                {actionBtnText}
              </Button>
            </Grid>

            {/*----- Pagination -----*/}
            {showMultiple && (
              <Grid item sx={sliderStyling}>
                <ChevronLeftIcon onClick={prevCard} sx={{ cursor: 'pointer' }} />
                <Typography>
                  Anzeige {current} / {total}
                </Typography>
                <ChevronRightIcon onClick={nextCard} sx={{ cursor: 'pointer' }} />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Card>
    </Paper>
  )
}
