import React from 'react'
import { logoByCategory, logoByListingUse } from './helpers'

interface LogosOverlayProps {
  mode: 'normal' | 'stadtguthaben'
}

const LogosOverlay: React.FC<LogosOverlayProps> = ({ mode }) => {
  const renderLogos = () => {
    const imageSize = '35px'

    if (mode === 'normal') {
      // Render logos by listing use
      return (
        <>
          <div style={{ marginBottom: '6px' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={logoByListingUse('commercial', false)}
                alt="Commercial"
                style={{ width: imageSize, height: imageSize }}
              />
              <span
                style={{ color: '#fff', fontWeight: 'bold', fontSize: '14px', marginLeft: '4px' }}
              >
                Gewerblich
              </span>
            </div>
          </div>
          <div style={{ marginBottom: '6px' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={logoByListingUse('residential', false)}
                alt="Residential"
                style={{ width: imageSize, height: imageSize }}
              />
              <span
                style={{ color: '#fff', fontWeight: 'bold', fontSize: '14px', marginLeft: '4px' }}
              >
                Wohnen
              </span>
            </div>
          </div>
          <div style={{ marginBottom: '6px' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={logoByListingUse('other', false)}
                alt="Andere"
                style={{ width: imageSize, height: imageSize }}
              />
              <span
                style={{ color: '#fff', fontWeight: 'bold', fontSize: '14px', marginLeft: '4px' }}
              >
                Andere
              </span>
            </div>
          </div>
        </>
      )
    } else if (mode === 'stadtguthaben') {
      // Render logos by category
      return (
        <>
          <div style={{ marginBottom: '6px' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={logoByCategory('Einkaufen', false)}
                alt="Einkaufen"
                style={{ width: imageSize, height: imageSize }}
              />
              <span
                style={{ color: '#fff', fontWeight: 'bold', fontSize: '14px', marginLeft: '4px' }}
              >
                Einkaufen
              </span>
            </div>
          </div>
          <div style={{ marginBottom: '6px' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={logoByCategory('Gastronomie', false)}
                alt="Gastronomie"
                style={{ width: imageSize, height: imageSize }}
              />
              <span
                style={{ color: '#fff', fontWeight: 'bold', fontSize: '14px', marginLeft: '4px' }}
              >
                Gastronomie
              </span>
            </div>
          </div>
          <div style={{ marginBottom: '6px' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={logoByCategory('Dienstleistung', false)}
                alt="Dienstleistung"
                style={{ width: imageSize, height: imageSize }}
              />
              <span
                style={{ color: '#fff', fontWeight: 'bold', fontSize: '14px', marginLeft: '4px' }}
              >
                Dienstleistung
              </span>
            </div>
          </div>
        </>
      )
    }
  }

  return (
    <div style={{ position: 'absolute', top: '20px', left: '10px', zIndex: 1 }}>
      <div
        style={{
          backgroundColor: 'rgba(50,50,50,0.7)',
          padding: '10px',
          borderRadius: '8px',
          display: 'inline-block' // Make width flexible based on content
        }}
      >
        {renderLogos()}
      </div>
    </div>
  )
}

export default LogosOverlay
