import { ListingDetails, ListingsApprovalStatusEnum } from '~services/listings'
import { fetcher } from '~services/utils'
import useSWRMutation from 'swr/mutation'

export const updateListingStatus =
  (listingId: number | string | undefined) =>
  async (
    url: string,
    {
      arg
    }: {
      arg: {
        listingId?: string
        newStatus: ListingsApprovalStatusEnum
        authToken: string | undefined
      }
    }
  ) => {
    const headers = {
      Authorization: `Bearer ${arg.authToken}`
    }

    return (await fetcher(
      /* eslint-disable-line */
      `listings/${listingId}/status?status=${arg.newStatus}`,
      'PATCH',
      headers
    )) as ListingDetails
  }

export const useUpdateListingStatus = (listingId: number | undefined) => {
  const { data, trigger, error } = useSWRMutation(
    listingId ? `listings/${listingId}/status` : null,
    updateListingStatus(listingId)
  )

  return {
    listing: data,
    error,
    trigger
  }
}
