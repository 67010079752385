import { ChangeEvent, useEffect, useState } from 'react'
import { Box, Grid, IconButton, MenuItem, Typography } from '@mui/material'
import {
  AccessTime as AccessTimeIcon,
  ArrowBackIos as ArrowBackIosIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
  LocalOfferOutlined as LocalOfferIcon,
  LocationOnOutlined as LocationOnIcon
} from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { useAuthenticator } from '@aws-amplify/ui-react'

import { ReactComponent as AreaIcon } from '~assets/images/svgs/area-icon.svg'
import { StyledTextField } from '~components/input'
import { CardComponent } from '~components/card'
import { OrientationEnum, Pagination } from '~types/ui'
import {
  ListingDetails,
  ListingsApprovalStatusEnum,
  ListingsStatusFilters,
  useListings
} from '~services/listings'
import { useOwner, UserRoleEnum, useUser } from '~services/owner'

export const MaklerVacancies = () => {
  const navigate = useNavigate()

  const { user } = useAuthenticator(context => [context.user])
  const { userRole } = useUser()
  const { owner } = useOwner(user?.username)
  const [pageNumber, setPageNumber] = useState(1)
  const [statusFilters, setStatusFilters] = useState<ListingsStatusFilters>({
    approval_status: ListingsApprovalStatusEnum.approved
  })
  const [pagination, setPagination] = useState<Pagination>({ limit: 6, offset: 0, previous: false })
  const [paginationState] = useState<Pagination>(pagination)
  const { listings, isLoading: isListingLoading } = useListings(statusFilters, pagination)

  useEffect(() => {
    userRole === UserRoleEnum.Write &&
      owner &&
      owner.owner_id &&
      setStatusFilters(prev => ({ ...prev, owner_id: owner.owner_id }))
  }, [owner, userRole])

  const handlePageChange = (direction: 'previous' | 'next') => () => {
    if (direction === 'previous') {
      setPagination(() => ({
        previous: true,
        limit: pagination.limit,
        offset: listings?.offset_previous ? listings.offset_previous : 0
        // previous: true
      }))
      setPageNumber(pageNumber - 1)
    } else {
      setPagination(() => ({
        previous: false,
        limit: pagination.limit,
        offset: listings?.offset_next ? listings.offset_next : 0
      }))
      setPageNumber(pageNumber + 1)
    }
  }
  const goToCardDetails = (listingId: number) => () => {
    navigate(
      `${statusFilters.approval_status !== ListingsApprovalStatusEnum.approved ? '/profil' : ''}/${
        statusFilters.approval_status === ListingsApprovalStatusEnum.approved
          ? `mieten/${listingId}/from_admin`
          : `meine-anzeigen/${listingId}`
      }`
    )
  }

  const translateListingStatus = (status: string) => {
    switch (status) {
      case 'approved': {
        return 'genehmigt'
      }
      case 'rejected': {
        return 'abgelehnt'
      }
      case 'draft': {
        return 'entwurf'
      }
      default: {
        // we'll set the submitted state to be the default
        return 'eingereicht'
      }
    }
  }

  const handleStatusFiltering = (event: ChangeEvent<HTMLInputElement>) => {
    setPageNumber(1)
    setStatusFilters(prev => ({
      ...prev,
      approval_status:
        ListingsApprovalStatusEnum[event.target.value as keyof typeof ListingsApprovalStatusEnum]
    }))
  }

  const renderListing = (listing: ListingDetails) => (
    <CardComponent
      key={listing.listing_id}
      images={
        listing.attachments.length > 0
          ? listing.attachments.map(image =>
              image.s3_presigned_url ? image.s3_presigned_url : '/default.png'
            )
          : ['/default.png']
      }
      title={listing.title}
      actionBtnText={
        statusFilters.approval_status === ListingsApprovalStatusEnum.approved
          ? 'Details anzeigen'
          : 'Entwurf anzeigen'
      }
      actionBtnCallback={goToCardDetails(listing.listing_id)}
      orientation={OrientationEnum.horizontal}
    >
      <Grid id="outerContainer" container direction="row" gap={2} mt={2}>
        <Grid container item direction="column" xs={6} gap={2}>
          <Grid item display="flex" alignItems="center" gap={1}>
            <LocationOnIcon sx={{ color: 'primary.dark' }} />
            <Typography>
              {listing?.address?.streetname_number}, {listing?.address?.city}
            </Typography>
          </Grid>

          <Grid item display="flex" alignItems="center" gap={1}>
            <LocalOfferIcon sx={{ color: 'primary.dark' }} />
            <Typography>€ {listing.price.toLocaleString('de-DE')}</Typography>
          </Grid>

          <Grid item display="flex" alignItems="center" gap={1} marginTop={8}>
            <Typography color="secondary.contrastText">
              Status: {translateListingStatus(listing.approval_status)}
            </Typography>
          </Grid>
        </Grid>

        <Grid container item direction="column" xs={5} gap={2}>
          <Grid item display="flex" alignItems="center" gap={1}>
            <AreaIcon /> <Typography>{listing.usable_floor_area} m²</Typography>
          </Grid>

          <Grid item display="flex" alignItems="center" gap={1}>
            <AccessTimeIcon sx={{ color: 'primary.dark' }} />
            <Typography>
              ab {new Date(String(listing.available_from)).toLocaleDateString('de-DE')}
            </Typography>
          </Grid>

          <Grid item display="flex" alignItems="center" gap={1} marginTop={8}>
            <Typography color="secondary.contrastText">{listing.num_views} Ansichten</Typography>
          </Grid>
        </Grid>
      </Grid>
    </CardComponent>
  )

  return (
    <>
      <Typography variant="h4" color="primary.dark">
        Meine Anzeigen
      </Typography>
      <StyledTextField
        select
        onChange={handleStatusFiltering}
        defaultValue="approved"
        size="small"
        sx={{
          width: '15rem',
          marginTop: '1rem',
          marginBottom: '1.5rem',
          color: 'primary.light',
          '& .MuiOutlinedInput-root': {
            color: 'secondary.contrastText'
          }
        }}
      >
        <MenuItem sx={{ color: 'primary.light' }} value="all">
          Alle Anzeigen
        </MenuItem>
        <MenuItem sx={{ color: 'primary.light' }} value="draft">
          Entwürfe
        </MenuItem>
        <MenuItem sx={{ color: 'primary.light' }} value="submitted">
          Eingereichte Anzeigen
        </MenuItem>
        <MenuItem sx={{ color: 'primary.light' }} value="approved">
          Veröffentlichte Anzeigen
        </MenuItem>
        <MenuItem sx={{ color: 'primary.light' }} value="rejected">
          Abgelehnte Anzeigen
        </MenuItem>
      </StyledTextField>

      {isListingLoading ? (
        <Box>wird geladen...</Box>
      ) : listings?.items && listings?.items.length > 0 ? (
        <>
          {listings?.items.map(renderListing)}

          <Box
            mt="1.5rem"
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative'
            }}
          >
            <Typography
              color="primary.light"
              sx={{ position: 'absolute', left: 0 }}
            >{`${listings.total} Anzeigen insgesamt`}</Typography>

            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              {pageNumber > 1 ? (
                <IconButton onClick={handlePageChange('previous')}>
                  <ArrowBackIosIcon sx={{ fontSize: 30, color: 'neutral' }} />
                </IconButton>
              ) : null}
              <>
                {pageNumber} / {Math.ceil(listings.total / paginationState.limit)}
              </>
              {pageNumber < listings.total / paginationState.limit ? (
                <IconButton onClick={handlePageChange('next')}>
                  <ArrowForwardIosIcon sx={{ fontSize: 30, color: 'neutral' }} />
                </IconButton>
              ) : null}
            </Box>
          </Box>
        </>
      ) : (
        <Typography variant="h5">Keine Anzeigen gefunden</Typography>
      )}
    </>
  )
}
