import React from 'react'
import { useTheme } from '@mui/material/styles'
import { Paper, Box, Typography } from '@mui/material'

interface Color {
  color: string
  label: string
}

const ColorLegend: React.FC<{ colors: Color[] }> = ({ colors }) => {
  const theme = useTheme()

  return (
    <Paper
      style={{
        padding: theme.spacing(2),
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
        borderRadius: theme.spacing(1),
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start'
      }}
    >
      {colors.map((color, index) => (
        <Box
          key={index}
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginRight: theme.spacing(2)
          }}
        >
          <div
            style={{
              width: theme.spacing(3),
              height: theme.spacing(3),
              borderRadius: '50%',
              marginRight: theme.spacing(1),
              backgroundColor: color.color
            }}
          ></div>
          <Typography
            sx={{ display: 'flex', justifyContent: 'right' }}
            variant="h5"
            color="textSecondary"
          >
            {color.label}
          </Typography>
        </Box>
      ))}
    </Paper>
  )
}

export default ColorLegend
