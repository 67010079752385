import { CrudTypes } from '~types/api'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { SWRHook } from 'swr'

export const BASE_URL = process.env.REACT_APP_BASE_URL

export const tokenMiddleware = (useSWRNext: SWRHook) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (key: any, fetcher: any, config: any) => {
    const { user } = useAuthenticator(context => [context.user])
    let jwtToken = ''

    user &&
      // The types of amplify are messed up. There has been an open issue about it since 2020
      // https://github.com/aws-amplify/amplify-js/issues/4927
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      user.getSession((error: Error, session: any) => {
        if (error) {
          console.error('Error getting user session:', error)
          return
        }

        const idToken = session.getIdToken().jwtToken
        jwtToken = idToken
      })

    const modifiedFetcher = async (
      url: string | URL,
      method: CrudTypes,
      header?: Record<string, string>,
      body?: Body
    ) => {
      const headers = {
        Authorization: `Bearer ${jwtToken}`,
        ...header
      }

      return await fetcher([url, method, headers, body])
    }
    return useSWRNext(key, modifiedFetcher, config)
  }
}

export const fetcher = <T>(
  url: string | URL,
  method: CrudTypes,
  headers?: Record<string, string>,
  body?: T
) =>
  fetch(`${BASE_URL}/${url}`, {
    method,
    headers: { 'Content-Type': 'application/json', ...headers },
    body: body ? JSON.stringify(body) : undefined
  })
    .then(r => r.json())
    .catch(error => {
      throw error
    })

export const fileFetcher = (
  url: string | URL,
  method: CrudTypes,
  body: FormData,
  headers?: Record<string, string>
) =>
  fetch(`${BASE_URL}/${url}`, {
    headers,
    method,
    body
  })
    .then(r => r.json())
    .catch(error => {
      throw error
    })
