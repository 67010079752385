import { useState, memo, useEffect } from 'react'
import { Box, Grid, Typography, Link, Button } from '@mui/material'
import { LocationOnOutlined as LocationOnIcon, Check as CheckIcon } from '@mui/icons-material'
import { Cartesian3 } from 'cesium'

import { CesiumViewer, SelectEntityHandler } from '~components/cesiumViewer'
import { OrientationEnum } from '~types/ui'
import { VoucherLocation, useStadtguthabenAPI } from '~services/locations'
import { CardComponentVoucher } from '~components/card/CardComponentVoucher'
import { MapViewProps } from './types'

/**
 * Renders the map view
 */
export const MapView = memo(({ locationFilters }: MapViewProps) => {
  const [data, setData] = useState<VoucherLocation[] | undefined>()
  const { filteredData: stadtguthabenShops, isLoading: isStadtguthabenLoading } =
    useStadtguthabenAPI(locationFilters)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(
    () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      setData(stadtguthabenShops)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [locationFilters, isStadtguthabenLoading]
  )

  const close = () => {
    setSelectedShop(undefined)
  }
  // This holds all the details of the shops
  const [selectedShop, setSelectedShop] = useState<VoucherLocation>()

  /**
   * Save the clicked entity to local state, so we can show the card component for it.
   * We compare the received entityId to what we have saved previously. If they match, it means that
   * we want to deselect the entity.
   * @param shopUUID - the ID of the clicked entity received from the viewer
   */
  const handleVoucherSystemClick: SelectEntityHandler = shopUUID => {
    if (shopUUID) {
      setSelectedShop(data?.find((i: VoucherLocation) => i.uuid === shopUUID.toString()))
    } else {
      setSelectedShop(undefined)
    }
  }

  return (
    <Box sx={{ position: 'relative' }}>
      {isStadtguthabenLoading ? (
        <Box>wird geladen...</Box>
      ) : (
        <CesiumViewer
          onClickVoucherSystem={handleVoucherSystemClick}
          voucherLocations={data}
          initialPosition={new Cartesian3(4177300, 933235, 4715550)}
        />
      )}

      {selectedShop && (
        <Box sx={{ position: 'absolute', top: '1rem', left: '1rem' }}>
          <CardComponentVoucher
            orientation={OrientationEnum.vertical}
            popup={true}
            actionBtnText=""
            title={selectedShop?.name}
            closeWindow={close}
          >
            {isStadtguthabenLoading ? (
              'wird geladen...'
            ) : (
              <Grid container direction="column" gap={1} mb="1rem">
                <Grid item>
                  <Grid container direction="row" gap={2} alignItems="center">
                    <Typography variant="h4">{selectedShop?.filter.name}</Typography>
                  </Grid>
                </Grid>

                {selectedShop?.filter.acceptor && (
                  <Grid item>
                    <Grid container direction="row" gap={2} alignItems="center">
                      <CheckIcon sx={{ color: 'primary.dark' }} />{' '}
                      <Typography>Akzeptiert Gutscheine</Typography>
                    </Grid>
                  </Grid>
                )}

                {selectedShop?.filter.seller && (
                  <Grid item>
                    <Grid container direction="row" gap={2} alignItems="center">
                      <CheckIcon sx={{ color: 'primary.dark' }} />{' '}
                      <Typography>Verkauft Gutscheine</Typography>
                    </Grid>
                  </Grid>
                )}

                <Grid item>
                  <Grid container direction="row" gap={2} alignItems="center">
                    <LocationOnIcon sx={{ color: 'primary.dark' }} />{' '}
                    <Typography>
                      {selectedShop?.address?.street}, {selectedShop?.address?.zip}{' '}
                      {selectedShop?.address?.city}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}

            {selectedShop?.contact.web && (
              <Grid item mt={2} sx={{ width: '100%' }}>
                <Grid container direction="row" gap={2} alignItems="center">
                  <Link href={selectedShop?.contact.web} target="_blank">
                    <Button sx={{ width: '100%' }} variant="contained">
                      Shop-Seite
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            )}
          </CardComponentVoucher>
        </Box>
      )}
    </Box>
  )
})
