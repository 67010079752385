import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import { CssBaseline } from '@mui/material'
import { Amplify } from 'aws-amplify'
import { Authenticator } from '@aws-amplify/ui-react'

import { TraunTwin } from '~pages/router'

import theme from './theme'
import '@aws-amplify/ui-react/styles.css'

import { AWSConfig } from '~config/awsConfig'
import { FilterProvider } from './components/filterPanel/FilterContext'

Amplify.configure(AWSConfig)

export const App = () => {
  return (
    <Authenticator.Provider>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <FilterProvider>
            <TraunTwin />
          </FilterProvider>
        </ThemeProvider>
      </BrowserRouter>
    </Authenticator.Provider>
  )
}
