import { ChangeEvent, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Container, Grid, IconButton, MenuItem, Typography } from '@mui/material'
import {
  AccessTime as AccessTimeIcon,
  ArrowBackIos as ArrowBackIosIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
  LocalOfferOutlined as LocalOfferIcon,
  LocationOnOutlined as LocationOnIcon
} from '@mui/icons-material'

import { CardComponent } from '~components/card'
import { StyledTextField } from '~components/input'
import { ReactComponent as AreaIcon } from '~assets/images/svgs/area-icon.svg'
import { OrientationEnum, Pagination } from '~types/ui'
import {
  ListingDetails,
  ListingsApprovalStatusEnum,
  ListingsStatusFilters,
  ListingUsesEnum,
  useListings
} from '~services/listings'

const cardCategoryColors = [
  { color: '#b2daed', label: 'Wohnen' },
  { color: '#f2d1a0', label: 'Gewerbe' },
  { color: '#e3bae8', label: 'Parken' },
  { color: '#c4e8c3', label: 'Leerstand' },
  { color: '#e8baae', label: 'Gastronomie' },
  { color: '#e6e6e6', label: 'Andere' }
]

export const VacancyApprovalList = () => {
  const navigate = useNavigate()
  const [statusFilters, setStatusFilters] = useState<ListingsStatusFilters>({
    approval_status: ListingsApprovalStatusEnum.submitted
  })
  const [pagination, setPagination] = useState<Pagination>({ limit: 6, offset: 0, previous: false })
  const [paginationState] = useState<Pagination>(pagination)
  const [pageNumber, setPageNumber] = useState<number>(1) // Fix: Specify the type as number
  const { listings, isLoading: isListingLoading } = useListings(statusFilters, pagination)

  const [categoryCounts, setCategoryCounts] = useState<{ [key: string]: number }>({}) // Fix: Specify the type as { [key: string]: number }

  const getCategory = (listingUse: ListingUsesEnum): string => {
    // Fix: Specify the parameter type and return type
    switch (listingUse) {
      case ListingUsesEnum.house:
      case ListingUsesEnum.apartment:
      case ListingUsesEnum.senior_apartment:
        return 'Wohnen'
      case ListingUsesEnum.shop:
      case ListingUsesEnum.office:
      case ListingUsesEnum.storage_room:
      case ListingUsesEnum.production:
      case ListingUsesEnum.trade:
        return 'Gewerbe'
      case ListingUsesEnum.garage:
      case ListingUsesEnum.parking_space:
        return 'Parken'
      case ListingUsesEnum.vacant_plot:
        return 'Leerstand'
      case ListingUsesEnum.gastronomy:
        return 'Gastronomie'
      default:
        return 'Andere'
    }
  }

  interface CategoryCounts {
    [key: string]: number
  }

  useEffect(() => {
    // Count listings in each category
    if (listings && listings.items) {
      const counts = listings.items.reduce((acc: CategoryCounts, item) => {
        const category = getCategory(item.listing_use)
        acc[category] = (acc[category] || 0) + 1
        return acc
      }, {})
      setCategoryCounts(counts)
    }
  }, [listings])

  const goToCardDetails = (listingId: number) => () => {
    navigate(
      `${statusFilters.approval_status !== ListingsApprovalStatusEnum.approved ? '/profil' : ''}/${
        statusFilters.approval_status === ListingsApprovalStatusEnum.approved
          ? `mieten/${listingId}/from_admin`
          : `meine-anzeigen/${listingId}`
      }`
    )
  }

  const handleStatusFiltering = (event: ChangeEvent<HTMLInputElement>) => {
    setPageNumber(1)
    setStatusFilters(prev => ({
      ...prev,
      approval_status:
        ListingsApprovalStatusEnum[event.target.value as keyof typeof ListingsApprovalStatusEnum]
    }))
  }

  const translateListingStatus = (status: string): string => {
    // Fix: Specify the return type
    switch (status) {
      case 'approved': {
        return 'genehmigt'
      }
      case 'rejected': {
        return 'abgelehnt'
      }
      default: {
        // we'll set the submitted state to be the default
        return 'eingereicht'
      }
    }
  }

  const handlePageChange = (direction: 'previous' | 'next') => () => {
    if (direction === 'previous') {
      setPagination(() => ({
        previous: true,
        limit: pagination.limit,
        offset: listings?.offset_previous ? listings.offset_previous : 0
      }))
      setPageNumber(pageNumber - 1)
    } else {
      setPagination(() => ({
        previous: false,
        limit: pagination.limit,
        offset: listings?.offset_next ? listings.offset_next : 0
      }))
      setPageNumber(pageNumber + 1)
    }
  }

  const renderListing = (listing: ListingDetails) => (
    <CardComponent
      key={listing.listing_id}
      images={
        listing.attachments.length > 0
          ? listing.attachments.map(image =>
              image.s3_presigned_url ? image.s3_presigned_url : '/default.png'
            )
          : ['/default.png']
      }
      title={listing.title}
      actionBtnText={
        statusFilters.approval_status === ListingsApprovalStatusEnum.approved
          ? 'Details anzeigen'
          : 'Entwurf anzeigen'
      }
      actionBtnCallback={goToCardDetails(listing.listing_id)}
      orientation={OrientationEnum.horizontal}
    >
      <Grid id="outerContainer" container direction="row" gap={2} mt={2}>
        <Grid container item direction="column" xs={6} gap={2}>
          <Grid item display="flex" alignItems="center" gap={1}>
            <LocationOnIcon sx={{ color: 'primary.dark' }} />
            <Typography>
              {listing?.address?.streetname_number}, {listing?.address?.city}
            </Typography>
          </Grid>

          <Grid item display="flex" alignItems="center" gap={1}>
            <LocalOfferIcon sx={{ color: 'primary.dark' }} />
            <Typography>€ {listing.price.toLocaleString('de-DE')}</Typography>
          </Grid>

          <Grid item display="flex" alignItems="center" gap={1} marginTop={8}>
            <Typography color="secondary.contrastText">
              Status: {translateListingStatus(listing.approval_status)}
            </Typography>
          </Grid>
        </Grid>

        <Grid container item direction="column" xs={5} gap={2}>
          <Grid item display="flex" alignItems="center" gap={1}>
            <AreaIcon /> <Typography>{listing.usable_floor_area} m²</Typography>
          </Grid>

          <Grid item display="flex" alignItems="center" gap={1}>
            <AccessTimeIcon sx={{ color: 'primary.dark' }} />
            <Typography>
              ab {new Date(String(listing.available_from)).toLocaleDateString('de-DE')}
            </Typography>
          </Grid>

          <Grid item display="flex" alignItems="center" gap={1} marginTop={8}>
            <Typography color="secondary.contrastText">{listing.num_views} Ansichten</Typography>
          </Grid>
        </Grid>
      </Grid>
    </CardComponent>
  )

  return (
    <Container>
      <Typography variant="h4" color="primary.dark">
        Alle Anzeigen
      </Typography>
      <StyledTextField
        select
        onChange={handleStatusFiltering}
        defaultValue="submitted"
        size="small"
        sx={{
          width: '15rem',
          marginTop: '1rem',
          marginBottom: '1.5rem',
          color: 'primary.light',
          '& .MuiOutlinedInput-root': {
            color: 'secondary.contrastText'
          }
        }}
      >
        <MenuItem sx={{ color: 'primary.light' }} value="all">
          Alle Anzeigen
        </MenuItem>
        <MenuItem sx={{ color: 'primary.light' }} value="submitted">
          Eingereichte Anzeigen
        </MenuItem>
        <MenuItem sx={{ color: 'primary.light' }} value="approved">
          Veröffentlichte Anzeigen
        </MenuItem>
        <MenuItem sx={{ color: 'primary.light' }} value="rejected">
          Abgelehnte Anzeigen
        </MenuItem>
      </StyledTextField>
      {/* Display category counts */}
      <Box sx={{ marginY: 4 }}>
        <Typography variant="h5" color="primary.dark" marginBottom="10px">
          Anzeigen nach Kategorie:
        </Typography>
        <Grid container spacing={2}>
          {cardCategoryColors.map((categoryColor, index) => (
            <Grid item key={index}>
              <Box
                bgcolor={categoryColor.color}
                width="100px"
                height="50px"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Typography variant="h4" color="primary.contrastText">
                  {categoryCounts[categoryColor.label] || 0}
                </Typography>
              </Box>
              <Typography color="primary.light">{categoryColor.label}</Typography>
            </Grid>
          ))}
        </Grid>
      </Box>

      {isListingLoading ? (
        <Box>wird geladen...</Box>
      ) : listings?.items && listings?.items.length > 0 ? (
        <>
          {listings?.items.map(renderListing)}

          <Box
            mt="1.5rem"
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative'
            }}
          >
            <Typography
              color="primary.light"
              sx={{ position: 'absolute', left: 0 }}
            >{`${listings.total} Anzeigen insgesamt`}</Typography>

            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              {pageNumber > 1 ? (
                <IconButton onClick={handlePageChange('previous')}>
                  <ArrowBackIosIcon sx={{ fontSize: 30, color: 'neutral' }} />
                </IconButton>
              ) : null}
              <>
                {pageNumber} / {Math.ceil(listings.total / paginationState.limit)}
              </>
              {pageNumber < listings.total / paginationState.limit ? (
                <IconButton onClick={handlePageChange('next')}>
                  <ArrowForwardIosIcon sx={{ fontSize: 30, color: 'neutral' }} />
                </IconButton>
              ) : null}
            </Box>
          </Box>
        </>
      ) : (
        <Typography variant="h4">Keine Anzeigen gefunden</Typography>
      )}
    </Container>
  )
}
