import { Box, Typography, useTheme } from '@mui/material'
import { Image } from 'mui-image'
import { NavLink } from 'react-router-dom'

import bigLogo from '~assets/images/logo-big-575x711.png'

export const InfoPage = () => {
  const theme = useTheme()

  const linkStyle = {
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.dark
    }
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Image src={bigLogo} width="30rem" />
      <Box>
        <Typography variant="h4" color="primary.dark">
          Immobilien-Gewerbefläche vermieten oder verkaufen
        </Typography>
        <Box sx={{ my: '2.5rem' }}>
          <Typography variant="h5" sx={{ my: '1rem', maxWidth: '35rem' }}>
            Wie kann ich ein Immobilien-Angebot einstellen?
          </Typography>
          <Typography variant="body1" sx={{ maxWidth: '35rem' }}>
            Sie haben eine Immobilie bzw. Räumlichkeiten, Sie für gewerbliche Zwecke vermieten oder
            verkaufen möchten, und die derzeit leer stehen bzw. demnächst/in absehbarer Zeit frei
            werden? Und dieses Angebot möchten Sie gern auf diesem Portal (im Leerstandsmanagement
            des TraunTwin) veröffentlichen? Sie können sich dafür unter „Mein Profil“ registrieren
            und einen Account anlegen. Sollten Sie bereits einen Account haben, dann melden Sie sich
            unter „Mein Profil“ an und reichen bitte die erforderlichen Unterlagen unter ihrem
            Zugang auf der Plattform "Gewerbefläche vermieten" ein. Dort werden Sie schrittweise
            durch den Antragsprozess geleitet. Sie haben die Möglichkeit, alle relevanten
            Informationen und Daten zum Gebäude bzw. den Räumlichkeiten einzupflegen und
            entsprechende erläuternde Anmerkungen zu machen. Sobald Sie den Antrag eingereicht
            haben, wird dieser geprüft und entsprechend freigegeben oder abgelehnt.{' '}
            <NavLink to="/login" style={linkStyle}>
              Hier einloggen
            </NavLink>{' '}
            oder{' '}
            <NavLink to="/registrieren" style={linkStyle}>
              registrieren
            </NavLink>
            .
          </Typography>
        </Box>
        <Box sx={{ my: '2.5rem' }}>
          <Typography variant="h5" sx={{ my: '1rem' }}>
            Wann wird mein Immobilien-Angebot erscheinen?
          </Typography>
          <Typography variant="body1" sx={{ maxWidth: '35rem' }}>
            Nachdem Ihr Angebot erfolgreich durch uns freigegeben worden ist, wird es auf der Karte
            und in der Liste erscheinen. Auf der Karte wird Ihr Angebot durch einen roten Marker zu
            erkennen sein. Sie haben noch Fragen oder benötigen eine Auskunft? Dann können Sie sich
            gern unter +49 8669 857122 und +49 8669 857136 sowie unter vorstand@ku-traunreut.de an
            uns wenden.
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}
