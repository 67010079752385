import { useEffect } from 'react'
import { Box, Button, FormControlLabel, RadioGroup, Typography, useTheme } from '@mui/material'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import dayjs from 'dayjs'
import useSWRMutation from 'swr/mutation'
import { useNavigate } from 'react-router-dom'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import { StyledRadio, StyledTextField } from '~components/input'
import { createOwner, CreateOwnerBody, OwnerTypeEnum, updateOwner, useUser } from '~services/owner'

import { UserFormType, UserFormProps } from '.'

export const UserForm = ({ info, userData, mutate }: UserFormProps) => {
  const navigate = useNavigate()
  const { jwtToken } = useUser()
  const theme = useTheme()

  const registrationFormSchema = z
    .object({
      firstname: z.string().nonempty('Dieses Feld muss ausgefüllt werden.'),
      lastname: z.string().nonempty('Dieses Feld muss ausgefüllt werden.'),
      owner_type: z.nativeEnum(OwnerTypeEnum),
      company_name: z.string().optional(),
      website: z.string().optional(),
      streetname: z.string().nonempty('Dieses Feld muss ausgefüllt werden.'),
      housenumber: z
        .string()
        .nonempty('Dieses Feld muss ausgefüllt werden.')
        .refine(value => !isNaN(+value), {
          message: 'Ungültige Nummer.'
        }),
      houseletter: z.string().optional(),
      zipcode: z.string().nonempty('Dieses Feld muss ausgefüllt werden.'),
      city: z.string().nonempty('Dieses Feld muss ausgefüllt werden.'),
      state: z.string().optional(),
      country: z.string().nonempty('Dieses Feld muss ausgefüllt werden.'),
      phone: z.string().nonempty('Dieses Feld muss ausgefüllt werden.'),
      // replaced with react-phone-input-2
      //   .refine(phoneNumber => isValidPhoneNumber(phoneNumber), {
      //     message: 'Ungültige Telefonnummer.'
      //   }),
      email: z
        .string()
        .nonempty('Dieses Feld muss ausgefüllt werden.')
        .email('Ungültiges E-Mail-Format')
    })
    .refine(
      data => {
        return data.owner_type === OwnerTypeEnum.commercial ? !!data.company_name : true
      },
      {
        message: 'Dieses Feld muss ausgefüllt werden.',
        path: ['company']
      }
    )
  type RegistrationFormSchemaType = z.infer<typeof registrationFormSchema>
  const { control, handleSubmit, register, watch, setError } = useForm<RegistrationFormSchemaType>({
    resolver: zodResolver(registrationFormSchema),
    defaultValues: {
      firstname: userData?.firstname || '',
      lastname: userData?.lastname || '',
      owner_type: userData?.owner_type || OwnerTypeEnum.commercial,
      company_name: userData?.company_name || '',
      website: userData?.website || '',
      streetname: userData?.streetname || '',
      housenumber: userData?.housenumber?.toString() || '', // Ensure housenumber is a string
      houseletter: userData?.houseletter || '',
      zipcode: userData?.zipcode || '',
      city: userData?.city || '',
      state: userData?.state || '',
      country: userData?.country || '',
      phone: userData?.phone || '',
      email: userData?.email || ''
    },
    mode: 'onBlur'
  })
  const watchedOwnerType = watch('owner_type')
  const { trigger, data: owner } = useSWRMutation('owners/', userData ? updateOwner : createOwner)

  useEffect(() => {
    register('company_name')
  }, [register])

  const onSubmit: SubmitHandler<UserFormType> = async data => {
    const phoneNumberWithPrefix = `+${data.phone}`
    data.phone = phoneNumberWithPrefix
    const normalizedRegistration: CreateOwnerBody = {
      ...data,
      created_at: dayjs(new Date()),
      username: data.email.split('@')[0]
    }

    await trigger({ ownerData: normalizedRegistration, authToken: jwtToken })
    if (mutate) mutate()

    navigate(userData ? '/profil/mein-profil' : '/registrieren/bestaetigung', { replace: true })
  }

  /**
   * If the owner object has the 'detail' field on it and nothing else, it means that the creation was
   * unsuccessful.
   */
  useEffect(() => {
    if (owner && Object.hasOwn(owner, 'detail')) {
      setError('email', {
        message:
          'Der Inhaber mit der oben genannten E-Mail-Adresse existiert bereits, bitte verwenden Sie eine andere E-Mail-Adresse.'
      })
    }
  }, [owner, setError])

  return (
    <Box
      id="registrationForm"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1.25rem',
        marginTop: '2rem',
        width: '72%'
      }}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Box id="fullName" sx={{ display: 'flex', gap: '1.25rem' }}>
        <Controller
          name="firstname"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <StyledTextField
              {...field}
              id="firstname"
              placeholder="Vorname*"
              size="small"
              variant="outlined"
              error={Boolean(error)}
              helperText={error?.message}
            />
          )}
        />

        <Controller
          name="lastname"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <StyledTextField
              {...field}
              id="lastname"
              placeholder="Nachname*"
              size="small"
              variant="outlined"
              error={Boolean(error)}
              helperText={error?.message}
            />
          )}
        />
      </Box>

      {!userData && (
        <>
          <Typography color="primary.dark">
            Bitte wählen Sie Ihre gewünschte Account-Art aus:*
          </Typography>
          <Controller
            control={control}
            name="owner_type"
            defaultValue={OwnerTypeEnum.commercial}
            render={({ field }) => (
              <RadioGroup {...field} row /*onChange={handleOwnerTypeChange(field.onChange)}*/>
                <FormControlLabel
                  label="Gewerblicher/Öffentlicher Anbieter"
                  componentsProps={{ typography: { color: 'secondary.contrastText' } }}
                  value={OwnerTypeEnum.commercial}
                  control={
                    <StyledRadio
                      sx={{
                        color: 'primary.main',
                        '&.Mui-checked': {
                          color: 'primary.dark'
                        }
                      }}
                    />
                  }
                />

                <FormControlLabel
                  label="Privater Anbieter"
                  componentsProps={{ typography: { color: 'secondary.contrastText' } }}
                  value={OwnerTypeEnum.private}
                  control={
                    <StyledRadio
                      sx={{
                        color: 'primary.main',
                        '&.Mui-checked': {
                          color: 'primary.dark'
                        }
                      }}
                    />
                  }
                />
              </RadioGroup>
            )}
          />
        </>
      )}
      {watchedOwnerType === OwnerTypeEnum.commercial ? (
        <Controller
          name="company_name"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <StyledTextField
              {...field}
              id="company_name"
              placeholder="Unternehmen*"
              size="small"
              variant="outlined"
              error={Boolean(error)}
              helperText={error?.message}
            />
          )}
        />
      ) : null}

      <Controller
        name="website"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <StyledTextField
            {...field}
            id="website"
            placeholder="Webseite"
            size="small"
            variant="outlined"
            error={Boolean(error)}
            helperText={error?.message}
          />
        )}
      />

      <Box id="streetDetails" sx={{ display: 'flex', gap: '1.25rem' }}>
        <Controller
          name="streetname"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <StyledTextField
              {...field}
              id="streetname"
              placeholder="Straße*"
              size="small"
              variant="outlined"
              error={Boolean(error)}
              helperText={error?.message}
            />
          )}
        />

        <Controller
          name="housenumber"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <StyledTextField
              {...field}
              id="housenumber"
              placeholder="Nummer*"
              size="small"
              variant="outlined"
              error={Boolean(error)}
              helperText={error?.message}
            />
          )}
        />

        <Controller
          name="houseletter"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <StyledTextField
              {...field}
              id="houseletter"
              placeholder="Buchstabe"
              size="small"
              variant="outlined"
              error={Boolean(error)}
              helperText={error?.message}
            />
          )}
        />
      </Box>

      <Box id="cityDetails" sx={{ display: 'flex', gap: '1.25rem' }}>
        <Controller
          name="city"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <StyledTextField
              {...field}
              id="city"
              placeholder="Stadt*"
              size="small"
              variant="outlined"
              error={Boolean(error)}
              helperText={error?.message}
            />
          )}
        />

        <Controller
          name="zipcode"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <StyledTextField
              {...field}
              id="zipcode"
              placeholder="PLZ*"
              size="small"
              variant="outlined"
              error={Boolean(error)}
              helperText={error?.message}
            />
          )}
        />
      </Box>

      <Box id="countryDetails" sx={{ display: 'flex', gap: '1.25rem' }}>
        <Controller
          name="country"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <StyledTextField
              {...field}
              id="country"
              placeholder="Land*"
              size="small"
              variant="outlined"
              error={Boolean(error)}
              helperText={error?.message}
            />
          )}
        />

        <Controller
          name="state"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <StyledTextField
              {...field}
              id="state"
              placeholder="Bundesland"
              size="small"
              variant="outlined"
              error={Boolean(error)}
              helperText={error?.message}
            />
          )}
        />
      </Box>

      <Controller
        name="phone"
        control={control}
        render={({ field }) => (
          <PhoneInput
            {...field}
            inputProps={{
              id: 'phone',
              required: true,
              autoFocus: true
            }}
            containerStyle={{
              border: `1px solid ${theme.palette.primary.main}`,
              borderRadius: '6px'
            }}
            inputStyle={{
              border: 'none',
              color: `${theme.palette.primary.dark}`
            }}
            prefix={'+'}
            country="de"
            onlyCountries={['de']}
            placeholder="Telefonnummer*"
            countryCodeEditable={false}
          />
        )}
      />

      <Controller
        name="email"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <>
            <StyledTextField
              {...field}
              id="email"
              disabled={Boolean(userData)}
              placeholder="E-Mail*"
              size="small"
              variant="outlined"
              error={Boolean(error)}
              helperText={error?.message}
            />
            {/* Display username message below email field if email is present */}

            {field.value && (
              <div>
                <Typography variant="body2" color={theme.palette.primary.light}>
                  Ihr Benutzername ist:
                </Typography>
                <Typography variant="h3" color={theme.palette.primary.dark}>
                  {field.value.split('@')[0]}{' '}
                </Typography>
              </div>
            )}
          </>
        )}
      />

      {info}

      <Button variant="contained" type="submit" sx={{ width: '12rem' }} color="success">
        {userData ? 'Speichern' : 'Konto erstellen'}
      </Button>
    </Box>
  )
}
