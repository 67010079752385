import { memo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Grid, IconButton, Typography } from '@mui/material'
import {
  AccessTime as AccessTimeIcon,
  LocalOfferOutlined as LocalOfferIcon,
  LocationOnOutlined as LocationOnIcon,
  ArrowBackIos as ArrowBackIosIcon,
  ArrowForwardIos as ArrowForwardIosIcon
} from '@mui/icons-material'
import { Pagination, OrientationEnum } from '~types/ui'
import { ReactComponent as AreaIcon } from '~assets/images/svgs/area-icon.svg'
import { CardComponent } from '~components/card'
import { ListingCard, GeneralListingUseEnum, useFilteredListings } from '~services/listings'
import { Building, ListViewProps } from '.'
import { fetcher } from '~services/utils'
import { Cartesian3 } from 'cesium'
import ColorLegend from './ColorLegend'

/**
 * Renders a paginated list of listings
 */
export const ListView = memo(({ pagination, listingFilters }: ListViewProps) => {
  const navigate = useNavigate()
  const [paginationState, setPaginationState] = useState<Pagination>(pagination)
  const { listings, isLoading: isListingsLoading } = useFilteredListings(
    listingFilters,
    paginationState
  )

  const [pageNumber, setPageNumber] = useState(1)
  const [isBuildingDetailsLoading, setIsBuildingDetailsLoading] = useState(false)

  const fetchBuildingDetails = async (listingId: number, gmlId: string) => {
    try {
      const response = await fetcher<Building>(
        `buildings/gml/${gmlId}?centroid_crs=4326`,
        'GET',
        {}
      )

      // Assuming selectedBuildingCoords is a string in the format "longitude, latitude"
      const [latitude, longitude] = response.centroid
        .split(',')
        .map((coord: string) => parseFloat(coord.trim()))

      if (!isNaN(longitude) && !isNaN(latitude)) {
        const selectedBuildingCartesian = Cartesian3.fromDegrees(longitude, latitude)

        navigate(`/mieten/${listingId}/true`, {
          state: { gmlId, selectedBuildingCoords: selectedBuildingCartesian }
        })
      }
    } catch (error) {
      console.error('Error fetching building details:', error)
    } finally {
      setIsBuildingDetailsLoading(false)
    }
  }

  const handleCardClick = (listingId: number, gmlId: string) => () => {
    fetchBuildingDetails(listingId, gmlId)
    setIsBuildingDetailsLoading(true)
  }

  const handlePageChange = (direction: 'previous' | 'next') => () => {
    if (direction === 'previous') {
      setPaginationState(() => ({
        previous: true,
        limit: pagination.limit,
        offset: listings?.offset_previous ? listings.offset_previous : 0
      }))
      setPageNumber(pageNumber - 1)
    } else {
      setPaginationState(() => ({
        previous: false,
        limit: pagination.limit,
        offset: listings?.offset_next ? listings.offset_next : 0
      }))
      setPageNumber(pageNumber + 1)
    }
  }

  const cardCategoryColors = [
    { color: '#b2daed', label: 'Wohnen' },
    { color: '#f2d1a0', label: 'Gewerblich' },
    { color: '#e6e6e6', label: 'Andere' }
  ]

  // Could also be swapped to listing_use to not confuse users, listing_use then has to be added to ListingCard, or listing_use added to filters
  const renderCard = (listing: ListingCard) => {
    let cardColor
    switch (listing.general_listing_use) {
      case GeneralListingUseEnum.residential:
        cardColor = cardCategoryColors[0].color
        break
      case GeneralListingUseEnum.commercial:
        cardColor = cardCategoryColors[1].color
        break

      default:
        cardColor = cardCategoryColors[2].color
        break
    }

    // export enum GeneralListingUseEnum {
    //   commercial = 'commercial',
    //   residential = 'residential',
    //   other = 'other',
    //   all = 'all'
    // }

    return (
      <CardComponent
        key={listing.listing_id}
        title={String(listing.title)}
        actionBtnText="Details anzeigen"
        actionBtnCallback={handleCardClick(listing.listing_id, listing.gml_id)}
        images={
          listing.attachments.length > 0
            ? listing.attachments.map(image =>
                image.s3_presigned_url ? image.s3_presigned_url : '/default.png'
              )
            : ['/default.png']
        }
        orientation={OrientationEnum.horizontal}
        cardColor={cardColor}
      >
        <Grid container direction="row" gap={2} mt={2}>
          <Grid container item direction="column" xs={6} gap={2}>
            <Grid item>
              <Grid container direction="row" gap={2} alignItems="center">
                <LocationOnIcon sx={{ color: 'primary.dark' }} />
                <Typography>
                  {listing.address?.streetname_number}, {listing.address?.city}
                </Typography>
              </Grid>
            </Grid>

            <Grid item>
              <Grid container direction="row" gap={2} alignItems="center">
                <LocalOfferIcon sx={{ color: 'primary.dark' }} />
                <Typography>€{listing.price}</Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid container item direction="column" xs={5} gap={2}>
            <Grid item>
              <Grid container direction="row" gap={2} alignItems="center">
                <AreaIcon /> <Typography>{listing.usable_floor_area} m²</Typography>
              </Grid>
            </Grid>

            <Grid item>
              <Grid container direction="row" gap={2} alignItems="center">
                <AccessTimeIcon sx={{ color: 'primary.dark' }} />{' '}
                <Typography>
                  ab {new Date(String(listing.available_from)).toLocaleDateString('de-DE')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardComponent>
    )
  }

  return (
    <>
      {isListingsLoading || isBuildingDetailsLoading ? (
        <Box>wird geladen...</Box>
      ) : listings?.items && listings?.items.length > 0 ? (
        <>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row', // Ensure items are stacked horizontally
              justifyContent: 'space-between', // Distribute items along the row
              alignItems: 'flex-end', // Align items to the end of the container
              marginBottom: 5,
              paddingRight: 10 // Add padding to the right to separate from the right edge
            }}
          >
            <Box>
              <ColorLegend colors={cardCategoryColors} />
            </Box>
            <Typography variant="h5" color="textSecondary">
              {listings.total} Ergebnisse gefunden
            </Typography>
          </Box>

          {listings.items.map(renderCard)}

          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {pageNumber > 1 ? (
              <IconButton onClick={handlePageChange('previous')}>
                <ArrowBackIosIcon sx={{ fontSize: 30, color: 'neutral' }} />
              </IconButton>
            ) : null}
            <>
              {pageNumber} / {Math.ceil(listings.total / paginationState.limit)}
            </>
            {pageNumber < listings.total / paginationState.limit ? (
              <IconButton onClick={handlePageChange('next')}>
                <ArrowForwardIosIcon sx={{ fontSize: 30, color: 'neutral' }} />
              </IconButton>
            ) : null}
          </Box>
        </>
      ) : (
        <Typography variant="h4">Keine Ergebnisse für den gewählten Filter gefunden</Typography>
      )}
    </>
  )
})
