import { Box, Container, Grid, Typography } from '@mui/material'
import Image from 'mui-image'

import footerLogo from '~assets/images/logo-footer-245x112.png'
import reactEuLogo from '~assets/images/REACT-EU-Logo.jpg'

export const Footer = () => (
  <Container sx={{ paddingY: '2rem', marginTop: 'auto' }}>
    <Grid container direction="row" justifyContent="space-between" alignItems="center">
      <Grid item>
        <Image src={footerLogo} width="auto" height="112px" />
      </Grid>

      <Grid
        item
        xs={3}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Image src={reactEuLogo} width="15rem" height="auto" />
        <Typography color="primary.light" textAlign="center">
          Dieses Projekt wird durch das Programm REACT-EU im Rahmen des Europäischen Fonds für
          regionale Entwicklung (EFRE) gefördert.
        </Typography>
      </Grid>

      <Grid item xs={3}>
        <Typography color="primary.light">
          Kommunalunternehmen für Stadtentwicklung, Projektsteuerung und Wirtschaftsförderung
        </Typography>
      </Grid>

      <Grid item>
        <Typography color="primary.dark">Vorstand</Typography>
        <Typography color="primary.light">Christian Ehinger</Typography>
        <Typography color="primary.light">Bernard Ruf</Typography>
      </Grid>

      <Grid item>
        <Typography>
          <Typography color="primary.dark" component="span">
            Telefon 1:
          </Typography>{' '}
          +49 8669 857122
        </Typography>
        <Typography>
          <Typography color="primary.dark" component="span">
            Telefon 2:
          </Typography>{' '}
          +49 8669 857136
        </Typography>
        <Typography color="primary.light">vorstand@ku-traunreut.de</Typography>

        <Box sx={{ marginTop: '1rem' }}>
          <Typography color="primary.dark">Rathausplatz 3</Typography>
          <Typography color="primary.dark">83301 Traunreut</Typography>
        </Box>
      </Grid>
    </Grid>
  </Container>
)
