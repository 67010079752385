import { Owner, OwnerApprovalStatusEnum } from '~services/owner/types'
import { fetcher } from '~services/utils'

export const updateOwnerStatus = async (
  url: string,
  {
    arg
  }: {
    arg: { newStatus: OwnerApprovalStatusEnum; username: string; authToken: string | undefined }
  }
) =>
  (await fetcher(`owners/${arg.username}/status?status=${arg.newStatus}`, 'PATCH', {
    Authorization: `Bearer ${arg.authToken}`
  })) as Owner
